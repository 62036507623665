import React, { useState, useEffect, useCallback } from 'react';
import { Navigate, Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useDispatch, useSelector } from "react-redux";
import { GetUserByUID } from '../../Slices/User';
import type { AppDispatch, RootState } from "../../Store/Store";
import { setHistoryLocation } from '../../Slices/HistoryLocation';
import { setAuth } from '../../Slices/Auth';
import { AUTH_LOGIN, AUTH_MODAL, AUTH_REGISTER } from '../../Consts/BaseUrl';
import { GetPlanId } from '../../Slices/Plan';
import { GetSubscriptionId } from '../../Slices/Subscription';
import { GetPlanDetailById } from '../../Services/PlanDetailArkabia.service';
import { GetPlanDetailId } from '../../Slices/PlanDetail';

interface ProtectedRouteProps {
    permissions?: string[];
    userType?: string[];
    redirectPath?: string;
    children: React.ReactNode;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
    permissions,
    userType = '',
    redirectPath = `?${AUTH_MODAL}=${AUTH_LOGIN}`,
    children,
    ...props
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const { userDetail } = useSelector((state: any) => state.user);
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState<boolean>(true);
    const [isLoggin, setIsLoggin] = useState<boolean>(false);
    const [user, setUser] = useState(() => getAuth().currentUser);

    const getUserDetail = useCallback(async (UID: string) => {
        try {
            setLoading(true);
            const response = await dispatch(GetUserByUID(UID)).unwrap();
            if (!response) {
                setLoading(false);
                return;
            }
            if (response.userType && response.userType.includes("user")) {
                await getPlanById(response.planId);
                await getSubscriptionById(response.subscriptionId);
            }
            setIsLoggin(true);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, [dispatch]);

    const getPlanById = useCallback(async (id: string) => {
        try {
            const response = await dispatch(GetPlanId(id)).unwrap();
            await getPlanDetail(response.planDetailId);
        } catch (error) {
            console.error(error);
        }
    }, [dispatch]);

    const getPlanDetail = useCallback(async (id: string) => {
        try {
            await dispatch(GetPlanDetailId(id)).unwrap();
        } catch (error) {
            console.error(error);
        }
    }, [dispatch]);

    const getSubscriptionById = useCallback(async (id: string) => {
        try {
            await dispatch(GetSubscriptionId(id)).unwrap();
        } catch (error) {
            console.error(error);
        }
    }, [dispatch]);

    const compareUserRole = useCallback(() => {
        if (!userDetail) return false;
        return userDetail.userType.some((u: any) => userType.includes(u));
    }, [userDetail, userType]);

    const goToUserHome = useCallback(() => {
        if (userDetail?.userType?.includes("user")) {
            navigate('/operaciones', { replace: true });
        } else if (userDetail?.userType?.includes("provider")) {
            navigate('/proveedor', { replace: true });
        }
    }, [navigate, userDetail]);

    const getAccessUrl = useCallback(() => {
        if (userDetail && compareUserRole()) {
            searchParams.delete(AUTH_MODAL);
            return children;
        } else {
            goToUserHome();
        }
    }, [children, compareUserRole, goToUserHome, searchParams, userDetail]);

    const redirectToLogin = useCallback(() => {
        if (isLoggin) return;
        dispatch(setHistoryLocation(location.pathname));
        if (!searchParams.get(AUTH_MODAL) && (!searchParams.get(AUTH_MODAL)?.includes(AUTH_LOGIN) || !searchParams.get(AUTH_MODAL)?.includes(AUTH_REGISTER))) {
            dispatch(setAuth({
                isLoggedIn: false,
                displayName: "",
                imgProfile: "",
                userId: "",
                uid: "",
                isProtectedRoute: true
            }));
            navigate(location.pathname + redirectPath, { replace: true });
            return null;
        }
    }, [dispatch, isLoggin, location.pathname, navigate, redirectPath, searchParams]);

    useEffect(() => {
        const auth = getAuth();
        setLoading(true);
        onAuthStateChanged(auth, (userCurrent) => {
            if (userCurrent) {
                setUser(userCurrent);
                getUserDetail(userCurrent.uid);
            } else {
                setLoading(false);
            }
        });
    }, [getUserDetail]);

    return (
        <>
            {!loading ? (
                (user && isLoggin) ? 
                getAccessUrl() : 
                redirectToLogin()
            ) : null}
        </>
    );
};

export default ProtectedRoute;