import React, { useEffect, useState } from 'react';

import './NineSeccion.css';
import { Plan } from '../../Plan/Plan';
import { Switch } from '../../Switch/Switch';
import { SwitchToggle } from '../../SwitchToggle/SwitchToggle';
import { GetPlansArkabia } from '../../../Services/PlanArkabia.service';
import { PlanInterface } from '../../../interfaces/PlansInterface';
import { toast } from 'react-toastify';
import { ToggleSwitch } from '../../ToggleSwitch/ToggleSwitch';
import { CardSummaryPay } from '../../CardSummaryPay/CardSummaryPay';
import { PlanSection } from '../../PlanSection/PlanSection';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { PLAN } from '../../../Consts/BaseUrl';

interface NineSeccionProps {
}

export const NineSeccion: React.FC<NineSeccionProps> = ({

}) => {

    const navigation = useNavigate();

    let [checked, setChecked] = useState(false);
    
    const [loading, setLoading] = useState<boolean>(false);
    const [currency, setCurrency] = useState<"USD" | "PEN">("USD");

    const [plans, setPlans] = useState<PlanInterface[]>([]);
    const [planSelected, setPlanSelected] = useState<PlanInterface | null>(null);

    // method to handle currency change
    // const handleCurrencyChange = (status: any) => {
    //     setCurrency(status as "USD" | "PEN");
    // }

    // // method to get plans from firebase
    // const getPlans = async () => {
    //     if(loading) return;

    //     setLoading(true);
    //     try {
    //         const response = await GetPlansArkabia();
    //         setPlanSelected(response[2]);
    //         setPlans(response);
    //         setLoading(false);
    //     } catch (error) {
    //         console.log("🚀 ~ getPlans ~ error:", error);
    //         setLoading(false);
    //         toast.error("Ocurrió un error al obtener los planes, por favor intenta nuevamente");
    //     }
    // }

    // useEffect(() =>{
    //     getPlans();
    // }, []);

    const handlePlanDetailSection = () => {
        const planDetailSectionId = "planDetailSectionId"; 
        navigation("/"+ PLAN + "#" + planDetailSectionId);
    }


    return (
        <div className="nine-section">
            <Helmet>
                <title>Arkabia | Agencia de Aduana</title>
                <meta name="description" 
                content="Elige el plan adecuado para tus necesidades en Arkabia, 
                con una calificación de 4.9/5 y más de 362 reseñas. Optimiza tus 
                importaciones con nuestros servicios aduaneros en Perú." />

                <meta name="keywords" content="plan de servicios, Arkabia, agencia de
                aduana, servicios aduaneros, calificación 4.9, optimización de importaciones, 
                precios, satisfacción del cliente, opciones de plan" />
            </Helmet>

            <div className="nine-section-header">
                <div className='nine-section-text'>
                    Elige el plan correcto para ti
                    {/* <b className='nine-section-text-grandient'> correcto </b> */}
                    
                </div>
            </div>
            {/* <div className='nine-section-change'>
                <div className="nine-section-switch">
                    <div className='nine-section-white small-bold nine-section-switch-text'>Soles</div>
                    <ToggleSwitch
                        textLeft="USD"
                        textRight="PEN"
                        onToggle={handleCurrencyChange}
                    />
                    <div className='nine-section-white small-bold nine-section-switch-text'>Dólares</div>
                </div>
                <div className='nine-section-text-grandient-min'>Ahorra 30% con el plan anual</div>
            </div> */}
            <div className="nine-section-row">
                <PlanSection />
            </div>
            <div>
                <div onClick={handlePlanDetailSection}
                    className='small-header-medium nine-section-button'>Comparar planes</div>
            </div>
        </div>
    );
};