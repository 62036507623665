import React, { useEffect, useRef } from 'react';

import './AnimationSeccion.css';
import { Helmet } from 'react-helmet-async';
import { ImageCarousel } from '../../ImageCarousel/ImageCarousel';
import { AnimationGallery, AnimationGallerySecond } from '../../../Consts/AnimationGallery';

interface AnimationSeccionProps {
}

export const AnimationSeccion: React.FC<AnimationSeccionProps> = ({

}) => {

    return (
        <div className="animation-section">
            <Helmet>
                <title>Arkabia | Agencia de Aduana</title>
                <meta name="description" 
                content="Arkabia cuenta con una calificación de 4.9/5 basada en 
                362 reseñas. Descubre por qué nuestros usuarios confían en nuestros 
                servicios aduaneros y logísticos en Perú." />

                <meta name="keywords" content="Arkabia, reseñas, agencia de aduana, 
                servicios aduaneros, calificación 4.9, comentarios de usuarios, 
                logística, importaciones, satisfacción del cliente" />
            </Helmet>

            <div className="animation-section-content">
                <div className="animation-section-header">
                    <div className='animation-section-text'>
                        ¡Descubre cómo los
                        <b className='animation-section-text-grandient'> líderes </b>
                        del mercado
                    </div>
                    <div className='animation-section-text'>
                        realizan sus importaciones!
                    </div>
                </div>

                <div className="animation-section-header-mob">
                    <div className='animation-section-text'>
                        ¡Descubre cómo los
                        <b className='animation-section-text-grandient'> líderes </b>
                    </div>
                    <div className='animation-section-text'>
                        del mercado realizan sus
                    </div>
                    <div className='animation-section-text'>
                        importaciones!
                    </div>
                </div>
                <div className='animation-group-gallery'>
                    <ImageCarousel images={AnimationGallery}/>
                    <ImageCarousel images={AnimationGallerySecond}/>
                </div>
            </div>
        </div> 
    );
};