
export const PROFILE = 'perfil';

export const SERVICE = 'servicios';

export const SERVICE_SELLER = 'servicios-proveedor';

export const PLAN = 'precios';

export const TOOLS = 'herramientas';

export const FREQUENTLY_QUESTIONS = 'preguntas-frecuentes';

export const QUOTATION_MULTIPLE = 'cotizacion-multiple';

export const PRE_REGISTER = 'pre-registro';

export const ORDER = 'orden';

export const CHANGE_PLAN = 'cambio-plan';

export const OPERATIONS = 'operaciones';

export const QUOTATION = 'cotizaciones';

export const REQUEST_QUOTE = 'solicitud-cotizacion'

export const DETAIL = 'detalle';

export const CUSTOMS = 'agenciamiento';

export const CHAT = 'mensajes';

export const RESUME = 'resumen';

export const MOBILE = 'mobile';

export const CONTACT = 'contacto';

export const EMPRESA = 'mi-empresa';

export const RESENA = 'mi-resena';

export const INFORMATION = 'mi-informacion';

export const MY_SUBSCRIPTION = 'mi-suscripcion'; 

export const RECEIPTS = 'mis-recibos';

export const INVOICE = 'facturacion';

export const DISPUTES = 'mi-disputas';

export const PROVIDER = 'proveedor';

export const PROCESS_ORDER = 'proceso-orden';

export const REFERENCE = 'mis-referencias';

export const BALANCE = 'mi-saldo';

export const NEW_CUSTOMS = 'nuevo-agenciamiento'

export const COMPARE = 'comparar'

export const PORT_URL = 'https://olivia.icontainers.com/api/v2/locations/maritime/places?term='

export const AIRPORT_URL = 'https://www.kayak.com.pe/mvm/smartyv2/search?f=j&s=airportonly&where='

export const HEROKU_CORS = 'https://cors-anywhere.herokuapp.com/'; 

export const SHIPTRACKING = 'https://www.myshiptracking.com/requests/';

export const HELP_MODAL_SERVICE = 'ActiveHelpService';

export const HELP_MODAL_PHASE2 = 'ActiveHelpPhase2';

export const HELP_MODAL_PHASE5 = 'ActiveHelpPhase5';

export const HELP_MODAL_REQUESTSERVICE = 'ActiveHelpRequestService';

export const HELP_MODAL_ORDERS = 'ActiveHelpOrders';

export const HELP_MODAL_COMPARE = 'ActiveHelpCompare';

export const MARINTRAFFIC_API = 'https://www.marinetraffic.com/en/search/searchAsset?what=vessel&term=';

export const VESSELREGISTER = 'https://vesselregister.dnv.com/vesselregister/vessel/get?term';

export const API_COUNTRY = 'https://restcountries.com/v3.1';

export const API_CITY = 'https://countriesnow.space/api/v0.1/countries/cities';

export const CORS_PROXY = 'https://cors-proxy4.p.rapidapi.com/'

export const COUNTRY_FLAG_BASE = 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/flags/4x3/';

export const API_SUNAT_TIPO_CAMBIO = 'https://www.sunat.gob.pe/a/txt/tipoCambio.txt';

export const URL_CULQI_API = 'https://api.culqi.com/v2'

export const URL_CULQI_CLI = 'https://checkout.culqi.com/js/v4'

export const URL_CULQI_CUSTOM_CHECKOUT = 'https://js.culqi.com/checkout-js';

// auth consts
export const AUTH_MODAL = 'auth';
export const AUTH_LOGIN = 'login';
export const AUTH_REGISTER = 'register';
export const AUTH_REGISTER_CODE = 'registerCode';
export const AUTH_QUOTATION_LINK = 'quotationLink';
export const AUTH_REGISTER_STEP = 'registerStep';

// plan consts
export const PLAN_MODAL = 'plan';
export const PLAN_SECONDATY_MODAL = 'secondaryPlan';
export const PLAN_CURRENCY_MODAL = 'currency';

export const PLAN_FREE_ID = "LiGlpdkijeQ4rSwqdhhd";

export const OPERATION_ID = "60KdrbTkvSDul2gv9FdE";
export const REQUESTQUOTE_IDS: string[] = ["75xxYtTZ2Gka102PLNjCTest", "07fAJu28M86bO4kRpNQiTest"];



// modal requestQuote consts

export const SHOW_MODAL = 'showModal';

export const MODAL_REQUEST = 'requestQuote';

export const ACTION_MODAL_REQUEST = 'actionModalRequest';

export const STORAGE = 'https://storage.googleapis.com/storage-beta.arkabia.com';

export const STORAGE_ASSETS = STORAGE+"/public/assets";

// api consts

export const FUNCTIONS_URL = "https://us-central1-arkabia-mvp-v1.cloudfunctions.net";

export const LOCAL_FUNCTIONS_URL = "http://localhost:4040/arkabia-beta/us-central1/api";
export const FUNCTIONS_API = FUNCTIONS_URL+"/api";
export const FUNCTIONS_API_COMPARE_PLAN = "/comparePlans";
export const FUNCTIONS_API_VALIDATE_COUPON = "/validateCoupon";