import { ToolsInterface } from "../interfaces/ToolsInterface";

export const ToolsConsts: ToolsInterface[] = [
    {
        id: 0,
        position: true,
        title: 'Cotizaciones múltiples', 
        description: 'Optimiza tus costos, minimiza riesgos y trabaja con seguridad',
        // list: [
        //     {
        //         img: 'rayo-icon.png',
        //         item: 'Poca inversión'
        //     },
        //     {
        //         img: 'crecer-icon.png',
        //         item: 'Rápida ejecución'
        //     },
        //     {
        //         img: 'expand-icon.png',
        //         item: 'Éxito garantizado'
        //     }
        // ],
        btnNameSectionAnimation: 'Cotiza',
        btnNameResaltSectionAnimation: 'AHORA', 
        animation: './animations/Desktop/Web_Compara_1694px/compara_1694px.json',
        nameAnimation: 'compara_1694px',
        imgSectionAnimation: 'tools-1.png',
        url: '/precios',
        isExternal: false
    },
    // {
    //     id: 1,
    //     position: true,
    //     title: 'Controla absolutamente todo', 
    //     description: 'Inversión mínima, resultados rápidos.',
    //     description2: 'Almacena tus documentos, dale seguimiento a tu carga y chatea con tu agencia desde un solo lugar.',
    //     btnNameSectionAnimation: '',
    //     animation: './animations/Desktop/Web_Organiza_1694px/organiza_1694px.json',
    //     nameAnimation: 'organiza_1694px',
    //     imgSectionAnimation: 'tools-2.png',
    // },
    // {
    //     id: 2,
    //     position: false,
    //     title: 'Cursos y Eventos', 
    //     description: 'Aprende de los mejores',
    //     description2: 'Participa en cursos y eventos exclusivos para aprender a importar como el 1% de los importadores TOP, automatizar tus ventas y hacer crecer tu negocio con las mejores estrategias.',
    //     btnNameSectionAnimation: '¡Apúntate',
    //     btnNameResaltSectionAnimation: 'YA!',
    //     animation: '',
    //     nameAnimation: '',
    //     imgSectionAnimation: 'tools-3.png',
    //     url: '/precios',
    //     isExternal: false
    // },
];  

export const ToolsPageConsts: ToolsInterface[] = [
    {
        id: 0,
        position: true,
        title: 'Seguimiento en tiempo real', 
        description: '',
        description2: 'Mantén el control total de tus envíos. Rastreo preciso y actualizaciones al instante.',
        list: [
            {
                img: 'rayo-icon.png',
                item: 'Buques'
            },
            {
                img: 'crecer-icon.png',
                item: 'Aviones'
            },
            {
                img: 'expand-icon.png',
                item: 'Camiones'
            }
        ],
        btnNameSectionAnimation: '',
        btnNameResaltSectionAnimation: '',
        animation: '',
        nameAnimation: '',
        imgSectionAnimation: 'tools-4.png',
    },
    {
        id: 1,
        position: true,
        title: 'Agentes de compra en China', 
        description: '',
        description2: 'Accede a una lista de agentes de compra en China verificada para que busquen por ti el producto que tu cliente necesita.',
        btnNameSectionAnimation: '',
        animation: '',
        nameAnimation: '',
        imgSectionAnimation: 'tools-6.png',
    },
    {
        id: 2,
        position: true,
        title: 'Couriers y Viajeros de confianza', 
        description: '',
        description2: 'Compra desde cualquier tienda en EE.UU y deja que un courier o viajero de confianza lo traiga por ti.',
        btnNameSectionAnimation: '',
        btnNameResaltSectionAnimation: '',
        animation: '',
        nameAnimation: '',
        imgSectionAnimation: 'tools-5.png',
    },
];  

export const ToolsSharedContainerConsts: ToolsInterface[] = [
    {
        id: 0,
        position: true,
        title: '1. Indica qué traes', 
        description: '',
        description2: 'Escribe en el buscador dónde se encuentra tu carga, peso, volumen y la fecha en que esté lista para ser recogida en la fábrica del proveedor.',
        btnNameSectionAnimation: 'Buscar',
        btnNameResaltSectionAnimation: 'AHORA', 
        animation: '',
        nameAnimation: '',
        imgSectionAnimation: 'tools-contenedor-10.png',
        url: '/precios',
        isExternal: false
    },
    {
        id: 1,
        position: false,
        title: '2. Elige un contenedor', 
        description: '',
        description2: 'Reserva un espacio dentro del contenedor de un importador experimentado para que traiga tus productos al precio y momento más convenientes para ti.',
        btnNameSectionAnimation: '',
        animation: '',
        nameAnimation: 'organiza_1694px',
        imgSectionAnimation: 'tools-contenedor-11.png',
    },
    {
        id: 2,
        position: true,
        title: '3. Recibe tus producto', 
        description: '',
        description2: 'Recoge tu producto en el almacén del importador o pide que te lo envíe a tu dirección.',
        btnNameSectionAnimation: '¡Pruébalo',
        btnNameResaltSectionAnimation: 'YA!',
        animation: '',
        nameAnimation: '',
        imgSectionAnimation: 'tools-contenedor-12.png',
        url: '/precios',
        isExternal: false
    },
]; 