import axios from "axios"
import { API_CITY, API_COUNTRY, CORS_PROXY } from "../Consts/BaseUrl"

export const GetCountryList = async () => {
    try {
       
        const response= await axios.get(`${API_COUNTRY}/all`);
                
        let countries: any[] = response.data;
        if(countries.length > 0) {
           return countries.map((r:any)=> ({country: r.translations.spa.common, alpha2Code: r.cca2, phoneCode: r.idd.root+""+r.idd.suffixes})).sort((a:any, b:any) => a.country > b.country ? 1 : (a.country < b.country) ? -1 : 0)  
        } else {
            return []
        }
        
    } catch (error) {
        console.log("🚀 ~ file: Country.ts:10 ~ GetCountryList ~ error", error)
        return error;
    }
}

export const GetCityByCountry = async (countryName: string) => {
    try {
        let data = JSON.stringify({
            "country": countryName
          }); 
        const response = await axios.post(API_CITY, data, {
            headers: { 
                'Content-Type': 'application/json'
              },
        })
        return response.data.error ? [] : response.data.data;
    } catch (error) {
        console.log("🚀 ~ file: Country.ts:28 ~ GetCityByCOuntry ~ error", error)
        return error;
    }
}