import React from "react";
import './LoaderShimmerGroupContainer.css';

export const LoaderShimmerGroupContainer: React.FC<{}> = ({...props}) =>{
    return (
        <div className="card-groupContainer">
            <div className="loader-shimmer-groupContainer-row">
                <div className="loader-shimmer-groupContainer-logo shimmer-operation-animation"/>
                <div className="loader-shimmer-groupContainer-row-2">
                    <div className="loader-shimmer-groupContainer-column shimmer-operation-column-width">
                        <div className="loader-shimmer-groupContainer-text shimmer-operation-animation"/>
                        <div className="loader-shimmer-groupContainer-row-min">
                            <div className="loader-shimmer-groupContainer-text shimmer-operation-animation"/>
                            <div className="loader-shimmer-groupContainer-text shimmer-operation-animation"/>
                            <div className="loader-shimmer-groupContainer-text shimmer-operation-animation"/>
                        </div>
                    </div>

                    <div className="loader-shimmer-groupContainer-button shimmer-operation-animation"/>

                    <div className="loader-shimmer-groupContainer-column shimmer-operation-column-width-min">
                        <div className="loader-shimmer-groupContainer-text shimmer-operation-animation"/>
                        <div className="loader-shimmer-groupContainer-text shimmer-operation-animation"/>
                    </div>

                    <div className="loader-shimmer-groupContainer-column shimmer-operation-column-width-min">
                        <div className="loader-shimmer-groupContainer-text shimmer-operation-animation"/>
                        <div className="loader-shimmer-groupContainer-text shimmer-operation-animation"/>
                    </div>

                    <div className="loader-shimmer-groupContainer-column shimmer-operation-column-width-min">
                        <div className="loader-shimmer-groupContainer-text shimmer-operation-animation"/>
                        <div className="loader-shimmer-groupContainer-text shimmer-operation-animation"/>
                    </div>

                    <div className="loader-shimmer-groupContainer-progress"/>

                    <div className="loader-shimmer-groupContainer-price"/>
                </div>
            </div>
            {/* <div className="loader-shimmer-groupContainer-header">
                <div className="loader-shimmer-groupContainer-row">
                    <div className="loader-shimmer-groupContainer-logo shimmer-operation-animation"></div>
                    <div className="loader-shimmer-groupContainer-text shimmer-operation-animation"></div>
                </div>
                <div className="loader-shimmer-groupContainer-item shimmer-operation-animation"></div>
                <div className="loader-shimmer-groupContainer-item shimmer-operation-animation"></div>
                <div className="loader-shimmer-groupContainer-item shimmer-operation-animation"></div>
                <div className="loader-shimmer-groupContainer-item shimmer-operation-animation"></div>
                <div className="loader-shimmer-groupContainer-item shimmer-operation-animation"></div>
            </div>

            <div className="loader-shimmer-groupContainer-body">
                <div className="loader-shimmer-groupContainer-body_content">
                    <div className="loader-shimmer-groupContainer-table_item shimmer-operation-animation"></div>
                    <div className="loader-shimmer-groupContainer-table_item shimmer-operation-animation"></div>
                    <div className="loader-shimmer-groupContainer-table_item shimmer-operation-animation"></div>
                </div>
            </div> */}
        </div>
    )
} 