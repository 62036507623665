import React, { useEffect } from "react";

import './Tools.css';
import { SectionAnimation } from "../../../components/SectionAnimation/SectionAnimation";
import { PLAN, STORAGE_ASSETS } from "../../../Consts/BaseUrl";
import { ToolsPageConsts } from "../../../Consts/ToolsConsts";
import { useNavigate } from "react-router-dom";
import { SixSeccion } from "../../../components/Web/SixSeccion/SixSeccion";
import { TwelveSeccion } from "../../../components/Web/TwelveSeccion/TwelveSeccion";
import { EightSeccion } from "../../../components/Web/EightSeccion/EightSeccion";
import { PlanSection } from "../../../components/PlanSection/PlanSection";
import { TenSeccion } from "../../../components/Web/TenSeccion/TenSeccion";

export const Tools: React.FC<{}> = () => {

    const navigate = useNavigate();

    const handlePlan = () => {
        navigate("/"+PLAN);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="tools">
            <div className="tools-first">
                <div className="tools-content">
                    <div className="small-subheader tools-white tools-title-min">¿Quieres iniciar en el mundo de las importaciones sin arriesgar tu capital?</div>
                    <div className="tools-title tools-white">
                        Conviértete en un
                        <span className="tools-text-degradado"> Ghost Importer</span> 
                    </div>
                    <div className="tools-content-w" style={{paddingBottom: '20px'}}>
                        <div className="header-medium tools-white tools-subtitle">
                            Genera ingresos rápido en tu tiempo libre importando productos para 
                        </div>
                        <div className="header-medium tools-white tools-subtitle">
                            otras personas con la plataforma que lo hace todo por ti. ¡Empieza hoy y
                        </div>
                        <div className="header-medium tools-white tools-subtitle">sé parte del negocio más rentable y seguro del momento!</div>
                    </div>

                    <div className="tools-content-m" style={{paddingBottom: '20px'}}>
                        <div className="header-medium tools-white tools-subtitle">
                        Genera ingresos rápido en tu tiempo libre importando productos
                        </div>
                        <div className="header-medium tools-white tools-subtitle">
                        para otras personas con la plataforma que lo hace todo
                        </div>
                        <div className="header-medium tools-white tools-subtitle">
                        por ti ¡Empieza hoy sé parte del negocio más rentable
                        </div>
                        <div className="header-medium tools-white tools-subtitle">
                        y seguro del momento!
                        </div>
                    </div>

                    <div className="tools-content-s" style={{paddingBottom: '20px'}}>
                        <div className="header-medium tools-white tools-subtitle">
                        Genera ingresos rápido en tu tiempo libre importando  
                        </div>
                        <div className="header-medium tools-white tools-subtitle">
                        productos para otras personas con la plataforma que 
                        </div>
                        <div className="header-medium tools-white tools-subtitle">
                        lo hace todo por ti ¡Empieza hoy sé parte del negocio
                        </div>
                        <div className="header-medium tools-white tools-subtitle">
                        más rentable y seguro del momento!</div>
                    </div>

                    <div className="tools-content-xs" style={{paddingBottom: '20px'}}>
                        <div className="header-medium tools-white tools-subtitle">
                        Genera ingresos rápido en tu tiempo libre   
                        </div>
                        <div className="header-medium tools-white tools-subtitle">
                        importando productos para otras personas   
                        </div>
                        <div className="header-medium tools-white tools-subtitle">
                        con la plataforma que lo hace todo por ti  
                        </div>
                        <div className="header-medium tools-white tools-subtitle">
                        ¡Empieza hoy sé parte del negocio más </div>
                        <div className="header-medium tools-white tools-subtitle">
                        rentable y seguro del momento!</div>
                    </div>
                    <div className='small-boldheader tools-button' onClick={()=>handlePlan()}>
                        Empezar AHORA
                    </div>
                    <img className="tools-img-main" src={STORAGE_ASSETS+'/images/png/tools-main.png'}/>
                </div>
            </div>

            {/* <div className="tools-second">
                <div className="tools-second-header">
                    <div className="tools-white tools-second-title">
                        Una herramienta. Muchas posibilidades
                    </div>
                    <div className="small-boldheader tools-white tools-second-subtitle">
                        Todo lo que necesitas para escalar tu negocio de importaciones 
                        desde cero está aquí.
                    </div>
                </div>
                <div className="tools-second-body-content">
                    <div className="tools-second-body">
                        <div className="tools-second-item">
                            <img 
                                className="tools-second-img" 
                                src={STORAGE_ASSETS+'/images/png/rayo-icon.png'}/>
                            <div className="small-medium tools-white tools-item-title"> Optimiza costos </div>
                            <div className="paragraph tools-white tools-item-description">
                                Arkabia te permite maximizar tus ganancias en cada 
                                importación, asegurando que obtengas siempre la mejor 
                                relación calidad-precio en tus cotizaciones.
                            </div>
                        </div>
                        <div className="tools-second-item">
                            <img 
                                className="tools-second-img" 
                                src={STORAGE_ASSETS+'/images/png/crecer-icon.png'}/>
                            <div className="small-medium tools-white tools-item-title"> Reduce procesos </div>
                            <div className="paragraph tools-white tools-item-description">
                                Con Arkabia, tus importaciones no solo serán rápidas, sino 
                                también eficientes y organizadas de principio a fin.
                            </div>
                        </div>
                        <div className="tools-second-item">
                            <img 
                                className="tools-second-img" 
                                src={STORAGE_ASSETS+'/images/png/expand-icon.png'}/>
                            <div className="small-medium tools-white tools-item-title"> Evita incautaciones </div>
                            <div className="paragraph tools-white tools-item-description">
                                Arkabia reduce el riesgo de retenciones y sanciones al 
                                anticipar los permisos que Aduanas podría requerir, 
                                asegurando que tu carga llegue sin contratiempos.
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="tools-second">
                <div className="tools-title tools-second-title tools-white">
                    ¡Qué ofrece
                    <span className="tools-text-second-degradado"> Arkabia</span>!
                    
                </div>
            </div>

            <div className="tools-thrid">
                {ToolsPageConsts.map((item, index) => (
                    <SectionAnimation 
                        key={index}
                        tools={item}
                    />
                ))}
            </div>
            <SixSeccion/>
            <TwelveSeccion/>
            <EightSeccion/>
            <div className="tools-second">
                <div className="tools-title tools-thrid-title tools-white">
                    Elige el plan correcto para ti
                </div>
            </div>
            <PlanSection />
            <TenSeccion title={window.innerWidth < 690 ? "¿Tienes preguntas?" : "Preguntas frecuentes"}/>
        </div>
    )
}