import React, { useEffect, useRef, useState } from 'react';

import './ImageCarousel.css';
import { CommentsConsts } from '../../Consts/CommentsConsts';
import { CardComment } from '../CardComment/CardComment';
import { STORAGE_ASSETS } from '../../Consts/BaseUrl';

interface ImageCarouselProps {
    images: string[];
}

export const ImageCarousel: React.FC<ImageCarouselProps> = ({
    images
}) => {

    return (

        <div className="ImageCarousel">
            <div className="ImageCarousel-track">
                {images.concat(images).map((i, idx) =>
                     <img
                         className='ImageCarousel-image'
                         key={idx}
                         src={`${STORAGE_ASSETS}/images/png/${i}.png`}  
                     />
                 )}
            </div>
        </div>
    );
};