import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type DownDropdownWhiteProps = React.HTMLAttributes<HTMLDivElement> & {};

export const DownDropdownWhite: React.FC<DownDropdownWhiteProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/png/dropdown-arrow-white.png"}  alt=""  />
    </div>
  );
};