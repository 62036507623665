import React, { useEffect, useState,  useRef } from "react";

import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import ReactGA from "react-ga4";

// Importando Components
import { Button, OperationHeader } from "../../../../components";
import { CompanyList } from "../../../../components/CompanyList/CompanyList";
import { QuotationCard } from "../../../../components/Quotation/QuotationCard/QuotationCard";
import { Tabs } from "../../../../components/Tabs/Tabs";
import { ModalToken } from "../../../../components/Tokens/ModalToken/ModalToken";
import { PaySuccessful } from "../../../../components/PaySuccessful/PaySuccessful";
import { LoaderText } from "../../../../components/LoaderText/LoaderText";

// Importando constantes
import {
  OPERATIONS,
  COMPARE,
  QUOTATION,
  PROVIDER,
  SERVICE,
  HELP_MODAL_REQUESTSERVICE,
  STORAGE_ASSETS,
} from "../../../../Consts/BaseUrl";

// Importando Slice
import {  GetServicesByType } from "../../../../Slices/Service";
import { GetServiceTypeActive } from "../../../../Slices/ServiceType";

// Importanto interfaces
import { OperationInterface } from "../../../../interfaces/Operation";
import { ServiceInterface } from "../../../../interfaces/Service";
import { ServiceKeyboards } from "../../../../interfaces/ServiceKeyboards";

import { GetCompanyById } from "../../../../Services/Company.service";

//Importando utilidades
import { FormatDate } from "../../../../utils/formatDate";

// Importando store
import type { AppDispatch } from "../../../../Store/Store";

// Importando css
import "./OrdersQuotation.css";
import { toast } from "react-toastify";
import {
  SendRequestQuoteToOther,
} from "../../../../Services/RequestQuote.service";
import {
  GetOperationDetailRT,
} from "../../../../Services/Operation.service";
import { Loader } from "../../../../components/Loader/Loader";
import { HeadPage } from "../../../../components/HeadPage/HeadPage";
import { SearchTextField } from "../../../../components/SearchTextField/SearchTextField";
import { FilterByRelevance } from "../../../../Consts/FilterRelevance";
import { GetAllServicesKeyboardsActive } from "../../../../Services/ServiceKeyboards.service";
import { ModalAnimation } from "../../../../components/Modal/ModalAnimation/ModalAnimation";
import { ModalRQ } from "../../../../components/Modal/ModalSendRQ/ModalRQ";
import { ModalIA } from "../../../../components/Modal/ModalIA/ModalIA";
import { FilterService } from "../../../../components/FilterService/FilterService";
import { FilterCategory } from "../../../../Consts/FilterCategory";
import { SearchBlack } from "../../../../icons/outline/SearchBlack";
import { BackArrow } from "../../../../icons/outline/BackArrow";
import { FilteHorizontal } from "../../../../icons/outline/FilterHorizontal";
import { ModalFilter } from "../../../../components/Modal/ModalFilter/ModalFilter";
import { UserPlanResoucesInterface } from "../../../../interfaces/UserPlanResoucesInterface";
import { GetUserPlanResources } from "../../../../Services/UserPlanResources.service";
import { Search } from "../../../../icons/outline/Search";

interface OrdersQuotationPageProps {}

export const OrdersQuotationPage: React.FC<OrdersQuotationPageProps> = () => {
  let navigation = useNavigate();
  let params = useParams();
  const location = useLocation();

  const dispatch = useDispatch<AppDispatch>();
  const [searchParams] = useSearchParams();

  const {planDetail} = useSelector((state: any) => state.planDetail);
  const {userDetail} = useSelector((state: any) => state.user);

  
  const ref = useRef<HTMLDivElement>(null);

  const [companyQuote, setCompanyQuote] = useState<any[]>([]); // variable que almacena los servicios donde se envio la cotizacion => viene de la base de datos
  const [companyAddQuote, setCompanyAddQuote] = useState<any[]>([]); // variable temporal que almacena los servicios donde se van a enviar las cotizaciones
  const [addCompany, setAddCompany] = useState(true);
  const [activeTab, setActiveTab] = useState(1);
  const [showMinOptions, setShowMinOptions] = useState<boolean>(true);
  const [showModalToken, setShowModalToken] = useState(false);
  const [showModalThanks, setShowModalThanks] = useState(false);
  const [activeSearch, setActiveSearch] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const [operationDetail, setOperationDetail] = useState<OperationInterface>();

  const [resultServiceList, setResulServiceList] = useState<
    ServiceInterface[]
  >([]);


  const [serviceLeaked, setServiceLeaked] = useState<ServiceInterface[]>([]);

  const { serviceTypeList } = useSelector((state: any) => state.serviceType);
  const { serviceList } = useSelector((state: any) => state.service);

  const [serviceType, setServiceType] = useState('agenciamiento-de-aduana');

  const [loading, setLoading] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);

  const [isActiveTab, setIsActiveTab] = useState<boolean>(false);
  // const [showVerifyClick, setShowVerifyClick] = useState<boolean>(false);

  const [showModalAnimation, setShowModalAnimation] = useState<boolean>(false);

  const [showModalRQ, setShowModalRQ] = useState<boolean>(false);

  const [showModalIA, setShowModalIA] = useState<boolean>(false);

  const [userPlanResources, setUserPlanResources] = useState<UserPlanResoucesInterface>({
    createdAt: new Date(),
    limitRequests: 0,
    limitStorage: 0,
    numRequests: 0,
    storage: 0,
    updatedAt: new Date(),
    userId: ""
});

const [maxNumRequestQuote, setMaxNumRequestQuote] = useState<number>(3);

  /* metodos */
  // method to add service to array
  const onClickAddQuoteAgency = (service: any) => {
      if (
        companyAddQuote.findIndex((c) => c.companyId === service.companyId) !==
          -1 ||
        companyQuote.findIndex((c) => c.companyId === service.companyId) !== -1
      ) {
        setCompanyAddQuote((prev) => [
          ...companyAddQuote.filter((c) => c.companyId !== service.companyId),
        ]);


        setCompanyQuote((prev) => [
          ...companyQuote.filter((c) => c.companyId !== service.companyId),
        ]);
      } else {
        let numRequest = userPlanResources.limitRequests>=9999999999 ? companyQuote.length : userPlanResources.numRequests+companyQuote.length;
        if (numRequest < maxNumRequestQuote) {
          setCompanyQuote((prevState) => [...prevState, service]);
          setCompanyAddQuote((prevState) => [...prevState, service]);
        } else {
          toast.info(userPlanResources.limitRequests>=9999999999 ? "Solo puedes enviar 3 cotizaciones" : `Haz alcanzado el límite de cotizaciones gratuitas. Si deseas enviar más cotizaciones, actualiza tu plan.`);
        }
      }
    };

  // method to cancel send 
  const onClickCancelQuotation = () => {
    if (operationDetail) {
      setCompanyQuote((prev) => [
        ...companyQuote.filter((item) => !companyAddQuote.includes(item)),
      ]);
      setCompanyAddQuote([]);
      setAddCompany(false);
    }
    setShowMinOptions(false);
    setIsActiveTab(true);
  };

  /** metodo para enviar cotizacion */
  const handleSendClick = async () => {
    setLoading(true);
    if (operationDetail && operationDetail.id ) {
      let data: {
        operationId: string;
        requestQuoteId: string;
        quotationId: string;
        sendRequestQuote: any[];
      } = {
        operationId: operationDetail.id,
        requestQuoteId:
          operationDetail.requestQuotes.length > 0
            ? operationDetail.requestQuotes[0].id
            : operationDetail.requestQuoteId,
        quotationId: "",
        sendRequestQuote: companyAddQuote,
      };

      if (
        data.sendRequestQuote.length > 0 &&
        (operationDetail.requestQuotes.length === 0
          ? data.sendRequestQuote.length <= 3
          : data.sendRequestQuote.length < 3)
      ) {
        setAddCompany(false);
       
        setIsSending(true);
        //console.log(companyAddQuote)
        toast.success("¡Enviando cotizaciones!");
        GA4_add_to_card(companyAddQuote)
        SendRequestQuoteToOther(data).then((response) => {
          // console.log("🚀 ~ SendRequestQuoteToOther ~ response:", response)
          if (response) {
            setShowMinOptions(false);
            if (operationDetail && operationDetail.id) {
              toast.success("¡Tus cotizaciones fueron enviadas exitosamente!");
              setShowModalRQ(true);
              setTimeout(async () => {   
                // if exist operation name dont delete
                // operationDetail.requestQuotes.length === 0 && 
                // await DeleteRequestQuote(operationDetail.requestQuoteId);
                setCompanyQuote( (prev) => [...prev, companyAddQuote]);
                setCompanyAddQuote([]);
                // toast.success("¡Tus cotizaciones fueron enviadas exitosamente!");
               
                setLoading(false);
                
                setIsSending(false);
                await getOperationDetail(
                  operationDetail.id ? operationDetail.id : ""
                );
                // navigation(`/${OPERATIONS}/${REQUEST_QUOTE}/${operationDetail.id}`, { replace: true });
               
              }, 500);
            }
          }
         }).catch ((error => {
            console.log(
              "🚀 ~ file: OrdersQuotation.tsx:163 ~ handleSendClick ~ error:",
              error
            );
            setShowMinOptions(false);
            setIsSending(false);
            setLoading(false);
         }));
      } else {
        setLoading(false);
        toast.info("No ha seleccionado ninguna agencia");
      }
     
    } else {
      toast.info("Hubo un error al enviar las cotizaciones");
      setLoading(false);
    }
  };

  const handleGoOpetation = () => {
    navigation("/" + OPERATIONS);
  }

  /** metodo para ver cotizacion */
  const handleViewQuotation = (quotationId: number, requestQuoteId: number) => {
    navigation(
      "/" +
        OPERATIONS +
        "/" +
        operationDetail?.id +
        "/" +
        requestQuoteId +
        "/" +
        QUOTATION +
        "/" +
        (quotationId ? quotationId : "unknown")
    );
  };

  // metodo para ver detalle del servicio
  const handleServiceClick = (
    serviceId: string | undefined,
    serviceType: string
  ) => {
    if (
      serviceId &&
      serviceId.length > 0 &&
      serviceType &&
      serviceType.length > 0
    ) {
      navigation(`/servicios/${serviceType}/${serviceId}?mode=view`);
    }
  };

  const handleClickToken = () => {
    setShowModalToken(true);
  };

  const handleClickModalThanks = () => {
    setShowModalThanks(true);
    setShowModalToken(false);
  };

  const handleBackModalToken = () => {
    setShowModalToken(true);
    setShowModalThanks(false);
  };

  // metodo que envia a la pagina de comprar cotizacionees
  const handleClickCompare = (id: number) => {
    navigation(`/${OPERATIONS}/${operationDetail?.id}/${COMPARE}`);
  };

  //Listando cotizaciones de la operacion en tiempo real
  const getOperationDetail = async (operationId: string) => {
    if (!loading) {
      setLoading(true);

    try {
      GetOperationDetailRT(operationId, (response) => {
        if(response.userId === userDetail.id || response.authorizedUsers?.includes(userDetail.id)) { 
          setOperationDetail(response);
          setCompanyQuote(response.requestQuotes);
          setIsActiveTab(response.requestQuotes.length <= 3);
          getServices(response.requestQuoteType);

          setLoading(false);
        } else {
          navigation(`/${OPERATIONS}`);
          toast.error("No tienes permisos para ver esta operación");
        }
      });
    } catch (error) {
      console.log("🚀 ~ file: OrdersQuotation.tsx:294 ~ getOperationDetail ~ error:", error)
      toast.error("Ha ocurrido un error al obtener la información");
    }
    }
  };

  // Listando tipos de servicios
  // const getTypeServices = async () => {
  //   if (!loading) {
  //     setLoading(true);
  //     dispatch(GetServiceTypeActive())
  //       .unwrap()
  //       .then((response) => {
  //         setTypeList(response.map((r: any) => r.name));
  //         /*console.log(response)*/
  //         setLoading(false);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         setLoading(false);
  //       });
  //   }
  // };

  // metodo para obtener los servicios activos
  const getServices = async (type: string) => {
    if (!loading) {
      setLoading(true);
      dispatch(GetServicesByType(type))
        .unwrap()
        .then((response) => {
          // console.log(response);
          setResulServiceList(response);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  // metodo para filtrar los servicios por relevancia
  const handleFilterRelevance = (relevance: string) => {
    switch (relevance) {
        case FilterByRelevance[0]:
            orderByRatingServices();
            break;
        case FilterByRelevance[1]:
            orderByReviewServices();
            break;
        case FilterByRelevance[2]:
            orderByTimeServices();
            break;
        case FilterByRelevance[3]:
            orderByTimeServices();
            break;
        default:
            setServiceLeaked(serviceList);
            break;
    }
  }

  const handleFilterBy = (filter: string) => {
    let filter_split = filter.split(" ");
    if(filter === "Todos") {
      setResulServiceList(serviceList);
    } else {
      if(serviceType !== "busqueda-de-proveedor") {
        setResulServiceList(serviceList.filter((s: ServiceInterface) => s.keywords?.includes(filter_split[filter_split.length-1])));
      }
    } 
  }

  const orderByRatingServices = () => {
    if(resultServiceList.length>0) {
        setResulServiceList([...serviceList].sort((a, b) => { return b.rating - a.rating}));
    }
  }

  const orderByReviewServices = () => {
    if(resultServiceList.length>0) {
      setResulServiceList([...serviceList].sort((a, b) => { return b.numComments - a.numComments}));
    }
  }

  const orderByTimeServices = () => {
      if(resultServiceList.length>0) {
        setResulServiceList([...serviceList].sort((a, b) => { return Number(a.createdAt) - Number(b.createdAt)}));
      }
  }

  // obtiene serviciosKeyboard activos
  // const getServiceKeyword = async () => {

  //   try {
  //       const response: any = await GetAllServicesKeyboardsActive();
  //       setServiceKeyboards(response);
  //       setServiceKeyboardSearch(response)
  //   } catch (error) {
  //       console.log("🚀 ~ file: CompanyList.tsx:100 ~ getServiceKeyboards ~ error:", error)
  //       setServiceKeyboards([]);
  //       setServiceKeyboardSearch([]);
  //   }
  // }

  const searchService = (text: string) => {
    if (text.length > 0) {
      setResulServiceList(
        serviceList.filter(
          (s: any) =>
            s.companyName.toUpperCase().includes(text.toUpperCase()) ||
            s.title.toUpperCase().includes(text.toUpperCase())
        )
      );
    } else {
      setResulServiceList(
        serviceList.filter(
          (s: any) =>
            s.companyName.toUpperCase().includes(text.toUpperCase()) ||
            s.title.toUpperCase().includes(text.toUpperCase())
        )
      );
    }
  };

  const handleSearch = () => {
    setActiveSearch(true);
  }
  
  // metodo regresar
  const handleBeforeClick = () => {
    let urlLocation = location.pathname.split("/");
    if (urlLocation.length >= 2 && urlLocation[1] === PROVIDER) {
      navigation(`/${PROVIDER}/${OPERATIONS}`);
    } else {
      navigation("/" + OPERATIONS);
    }

  };

  const handleModalFilter = () => {
    setShowFilter(true);
  }

  const showAlertSelectCompany = () => {
    setShowModalIA(false);
    setShowMinOptions(true);
    setLoading(true);
    if (!toast.isActive("toast-orderQuotation")) {
      toast.warning(
        "Seleccione la(s) agencia(s) con las que deseas solicitar una cotización",
        { toastId: "toast-orderQuotation" }
      );
    }
    setAddCompany(true);
    setLoading(false);
   
  }

  const handleBackSearch = () => {
    setActiveSearch(false);
  }

  // Method to send add_to_cart event to GA4
  const GA4_add_to_card = (services: ServiceInterface[]) => {
    ReactGA.gtag("event", "add_to_cart", {
      currency: "USD",
      value: 1000*(services.length),
      items: services.map((s) => ({
        item_id: s.id,
        item_name: s.title,
        item_brand: s.companyName,
        item_category: s.serviceTypeLabel,
        item_list_id: "services_"+s.serviceType,
        item_list_name: "Services "+s.serviceTypeLabel,
        price: 1000,
        quantity: 1
      })),
    });
  }

      // method to get userPlanResources by userId
  const getUserPlanResources = async () => {
    // check if planDetail is empty
    if(!planDetail && !userDetail) {
        return;
    }
    try {
        setLoading(true);
        // check if user has userPlanResources
        if(userDetail.userPlanResourcesId && userDetail.userPlanResourcesId.length > 0) {
            const response = await GetUserPlanResources(userDetail.id);
            if(response) {
                // console.log("🚀 ~ getUserPlanResources ~ response", response);
                setUserPlanResources(response);    
                setMaxNumRequestQuote(response.limitRequests>=9999999999 ? 3 : (response.limitRequests-response.numRequests));
            }
            setLoading(false);
        }         
    } catch (error) {
        console.log("🚀 ~ getUserPlanResources ~ error:", error)
        toast.error("Hubo un error al obtener los recursos del plan");
    }
  }

    useEffect(() => {
        getUserPlanResources();
    }, [userDetail]);

  // obteniendo detalle de operacion
  useEffect(() => {
    //obtener servicios
    // if (serviceTypeList.length === 0) {
    //   getTypeServices();
    // }
    // getServiceKeyword();
    // getTypeServices();
    // getHelpModalStatus();

    if (params && params.operationId) {
      getOperationDetail(params.operationId);      
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      if (
        searchParams.get("sendOtherQuote") &&
        searchParams.get("sendOtherQuote") === "provider"
      ) {
        planDetail.price>0 && setShowModalIA(true);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    window.document.body.style.overflow = "unset";
  }, []);

  return (
    <div className="ordersQuotationPage-main" ref={ref}>
      {!loading ? (
        <>
          <OperationHeader
            operationDetail={operationDetail}
            teams={[]}
            onClickHome={() => {}}
            onClickBefore={handleBeforeClick}
            updateOperation={()=>{}}
            showDots = {userDetail.userType.includes('user')
              // && operationDetail?.requestQuotes.some(rq => !rq.quoteGenerated)
            }
          />
          <div className="ordersQuotationPage-container"> 
            <div className="ordersQuotationPage-header">
              <HeadPage
                title="¡Elige a tus agencias!"
                subTitle="Selecciona hasta 3 agencias de aduana y/o de carga para cotizar."
                titleH1
              />
            </div>
            <div className="ordersQuotationPage-subTitle--mobile paragraph">Selecciona hasta 3 agencias para cotizar tu carga.</div>

            <div className="ordersQuotationPage-body">
              <div className="ordersQuotationPage-company">
                <div className="ordersQuotationPage-company-filter">
                  {/* <SearchTextField 
                    setTextSearch={(e: any) => searchService(e)}
                    title='Buscar agencia'
                    darkIcon
                  /> */}
                  <>{activeSearch && 
                    <BackArrow 
                        className="ordersPage-left-action-left-icon-back" 
                        onClick={()=>handleBackSearch()}
                    />}
                    {!activeSearch ? 
                      <Search className="ordersPage-quotation-icon-search" onClick={handleSearch}/> :
                      <SearchTextField 
                          title={window.innerWidth <= 481 ? "Buscar": 'Buscar agencia'}
                          setTextSearch={(e:any)=> searchService(e)}
                          darkIcon 
                      />
                    }
                  </> 
                  <FilterService
                    title="Ordenar por"
                    titleDropdown="Todos"
                    filterItems= {FilterByRelevance}
                    handleItemSelected={handleFilterRelevance}
                  />
                  {serviceType && serviceType !== "busqueda-de-proveedor" && 
                  <FilterService
                    title={serviceType === "agenciamiento-de-aduana" ? "" : "Compra de" }
                    titleDropdown={serviceType === "agenciamiento-de-aduana" ? "Categoría" : "Todos" }
                    filterItems= {serviceType === "agenciamiento-de-aduana" ? FilterCategory : ["China", "EE.UU", "Todos"]}
                    handleItemSelected={handleFilterBy}
                  />}
                </div>
                {!loading && resultServiceList && operationDetail && (
                  <CompanyList
                    serviceData={resultServiceList}
                    chooseAgency={addCompany}
                    requestQuotes={operationDetail.requestQuotes}
                    requestSelected={companyAddQuote}
                    onClickAddQuoteAgency={onClickAddQuoteAgency}
                    onClickService={handleServiceClick}
                  />
                )}
              </div>

              <div className="ordersQuotationPage-services">
                {/** cotizaciones */}
                <div className="ordersQuotationPage-services-quotations">
                  {operationDetail && !loading && (
                    <QuotationCard
                      title={operationDetail.title}
                      quotationDate={FormatDate(
                        operationDetail.createdAt
                          ? operationDetail.createdAt.toDate()
                          : new Date()
                      )}
                      subtitle={operationDetail.type}
                      agency={companyQuote}
                      add={addCompany}
                      steps={false}
                      setAddCompany={setAddCompany}
                      onClickCancel={onClickCancelQuotation}
                      handleSendClick={handleSendClick}
                      handleQuotationClick={handleViewQuotation}
                      handleClickToken={handleClickToken}
                      handleClickCompare={() => handleClickCompare(1)}
                      setShowMinOptions={setShowMinOptions}
                      setIsActiveTab={setIsActiveTab}
                      courier={operationDetail?.requestQuotes.some(rq => rq.serviceType === 'courier')}
                      showAddButtom={showMinOptions}  
                      maxNumSelected={maxNumRequestQuote}
                    />
                  )}
                </div>
              </div>

              {/** mobile version */}
              {operationDetail && (
                <div className="ordersQuotationPage-mobile">
                  <Tabs
                    titles={
                      operationDetail.requestQuotes.length === 3
                        ? ["Cotizaciones"]
                        : ["Agencias", "Cotizaciones"]
                    }
                    active={
                      operationDetail.requestQuotes.length === 3 ? 1 : activeTab
                    }
                    setActiveTab={isActiveTab ? setActiveTab : () => []}
                    content={
                      operationDetail.requestQuotes.length === 3 ? (
                        <div className="ordersQuotationPage-services-quotations">
                          {/* <div className="paragraph-header ordersQuotationPage-services-quotation--text">Cotizaciones múltiples</div> */}
                          {operationDetail && (
                            <QuotationCard
                              title={operationDetail.title}
                              quotationDate={FormatDate(
                                operationDetail.createdAt
                                  ? operationDetail.createdAt.toDate()
                                  : new Date()
                              )}
                              subtitle={operationDetail.type}
                              agency={companyQuote}
                              add={addCompany}
                              // compare={compareQuotation}
                              steps={false}
                              setAddCompany={setAddCompany}
                              onClickCancel={onClickCancelQuotation}
                              handleSendClick={handleSendClick}
                              handleQuotationClick={handleViewQuotation}
                              handleClickToken={handleClickToken}
                              handleClickCompare={() => handleClickCompare(1)}
                              setActiveTab={setActiveTab}
                              setShowMinOptions={setShowMinOptions}
                              setIsActiveTab={setIsActiveTab}
                              courier={operationDetail.requestQuoteType === 'courier'}
                              maxNumSelected={maxNumRequestQuote}
                            />
                          )}
                        </div>
                      ) : activeTab === 1 ? (
                        <div className="ordersQuotationPage-tabs-service">
                          <div className="ordersQuotationPage-options">
                            {!activeSearch ? 
                              <SearchBlack className="chat-search-icon" onClick={handleSearch}/> :
                              <>
                                <BackArrow 
                                  className="chat-search-content-icon-back" 
                                  onClick={()=>setActiveSearch(false)}
                                />
                                <SearchTextField 
                                  setTextSearch={(e: any) => searchService(e)}
                                  title='Buscar agencia'
                                  darkIcon
                                />
                              </>
                              
                              }
                            <div className="ordersQuotationPage-tabs-service-options">
                              {/* <ServicesOptions
                                servicesOptions={serviceTypeList}
                                ServicesOptionsDataDropdown={typeList}
                                mobile={window.innerWidth < 960}
                                setTextSearch={(e: any) => searchService(e)}
                                onClick={()=>{}}
                                title='Buscar agencia'
                              /> */}
                              
                              {!activeSearch && 
                              <>
                                <FilterService
                                  title="Ordenar por"
                                  titleDropdown="Todos"
                                  filterItems= {FilterByRelevance}
                                  handleItemSelected={handleFilterRelevance}
                                />
                                  {serviceType && serviceType !== "busqueda-de-proveedor" && <FilterService
                                    title={serviceType === "agenciamiento-de-aduana" ? "" : "Compra de" }
                                    titleDropdown={serviceType === "agenciamiento-de-aduana" ? "Categoría" : "Todos" }
                                    filterItems= {serviceType === "agenciamiento-de-aduana" ? FilterCategory : ["China", "EE.UU", "Todos"]}
                                    handleItemSelected={handleFilterBy}
                                  />}
                              </>
                              }
                            </div>

                            {!activeSearch ? 
                              <div className="ordersQuotationPage-filter-icon" onClick={handleModalFilter}>
                                Filtros
                                <FilteHorizontal/>
                              </div> : 
                              null
                            }
                          </div>
                          {!loading && resultServiceList && operationDetail && (
                            <CompanyList
                              serviceData={resultServiceList}
                              chooseAgency={addCompany}
                              requestQuotes={operationDetail.requestQuotes}
                              requestSelected={companyAddQuote}
                              onClickAddQuoteAgency={onClickAddQuoteAgency}
                              onClickService={handleServiceClick}
                              hiddenVerify={true}
                            />
                          )}
                        </div>
                      ) : (
                        <div className="ordersQuotationPage-services-quotations">
                          {/* <div className="paragraph-header ordersQuotationPage-services-quotation--text">Cotizaciones múltiples</div> */}
                          {operationDetail && (
                            <QuotationCard
                              title={operationDetail.title}
                              quotationDate={FormatDate(
                                operationDetail.createdAt
                                  ? operationDetail.createdAt.toDate()
                                  : new Date()
                              )}
                              subtitle={operationDetail.type}
                              agency={companyQuote}
                              add={addCompany}
                              // compare={compareQuotation}
                              steps={false}
                              setAddCompany={setAddCompany}
                              onClickCancel={onClickCancelQuotation}
                              handleSendClick={handleSendClick}
                              handleQuotationClick={handleViewQuotation}
                              handleClickToken={handleClickToken}
                              handleClickCompare={() => handleClickCompare(1)}
                              setActiveTab={setActiveTab}
                              setShowMinOptions={setShowMinOptions}
                              setIsActiveTab={setIsActiveTab}
                              courier={operationDetail.requestQuoteType === 'courier'}
                              maxNumSelected={maxNumRequestQuote}
                            />
                          )}
                        </div>
                      )
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </>
      ) : isSending ? (
        <div className="ordersQuotationPage-loading">
          {" "}
          <LoaderText />
        </div>
      ) : (
        <Loader />
      )}

      <ModalToken
        show={showModalToken}
        setShow={setShowModalToken}
        handleClickModalThanks={handleClickModalThanks}
      />

      <PaySuccessful
        show={showModalThanks}
        setShow={setShowModalThanks}
        companyName="Arkabia"
        amount={5.0}
        handleBack={handleBackModalToken}
      />

      <ModalRQ 
        sendMore
        showModal = {showModalRQ}
        setShowModal={setShowModalRQ}
        handleClick={handleGoOpetation}
      />

      <ModalAnimation
        show={showModalAnimation}
        setShow={setShowModalAnimation}
        animation={[
          { 
            path: '/animations/Desktop/SendRequest/data_enviar_solicitud_1024.json',
            title: 'Enviar solicitud',
            image: STORAGE_ASSETS+'/images/png/sentoother.png',
            description: 'Selecciona hasta 3 agencias de aduana para cotizar tu carga.' 
          }
        ]}
      />

     {showModalIA  && <ModalIA 
        operaionId={operationDetail?.id ? operationDetail.id : ""}
        requestQuoteId={operationDetail?.requestQuoteId || ""}
        show={showModalIA}
        setShow={setShowModalIA}
        handleChoseAgencies={showAlertSelectCompany}
      />}

      <ModalFilter
        show={showFilter}
        setShow={setShowFilter}
        typeService={serviceType}
        handleFilterRelevance={handleFilterRelevance}
        FilterByRelevance={FilterByRelevance}
        FilterCategory={FilterCategory}
        handleFilter={handleFilterBy}
      />

      {showMinOptions && activeTab === 1 && ( !showFilter &&
        <div className="ordersQuotationPage-mobile-btn--container">
          <div className="ordersQuotationPage-mobile-btn--row">
            <div className="paragraph">
              <span className="ordersQuotationPage-btn--number">
                {" "}
                {companyQuote.length}
              </span>{" "}
              de <span className="ordersQuotationPage-btn--number"> 3</span>{" "}
              seleccionados
            </div>
            <div className="ordersQuotationPage-mobile-btn">
              <Button
                content="Cancelar"
                color="black-25"
                disabled={false}
                onClick={() => {
                  onClickCancelQuotation();
                  setActiveTab(2);
                  setShowMinOptions(false);
                }}
                role="btn-cancel-quotation-card"
              />
              <Button
                content="Enviar"
                disabled={false}
                color="blue-2"
                onClick={() => {
                  handleSendClick();
                  setActiveTab(2);
                }}
                role="btn-send-quotation-card"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
