import React, { useEffect, useState } from "react";

// Importando componentes

import { Badge } from "../Badge/Badge";

// Importando iconos
import { ArrowUp } from "../../icons/outline/ArrowUp";
import { ArrowDown } from "../../icons/solid/ArrowDown";
import { ArkabiaOp } from "../../icons/outline/ArkabiaOp";

// Importando interfaces
import { operationOrders, operationRequestQuotes } from "../../interfaces/Operation";

// Importando estilos
import './DashboardElements.css';
import { ConverMoneyFormat } from "../../utils/ConvertDecimal";
import { currencyUtils } from "../../utils/GetCurrency";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";
import { TableResponsive } from "../TableResponsive/TableResponsive";
import { ColumnTableResponsiveInterface } from "../../interfaces/ColumnTableResponsiveInterface";
import { GetServiceByCompanyId } from "../../Services/Service.service";
import { FormatDate } from "../../utils/formatDate";

interface DashboardElementsProps {
    id: string;
    operationId: string;
    numOperation: string;
    importationTitle: string; 
    type: "requestQuote" | "order";
    dataImportation: operationRequestQuotes[] | operationOrders[];
    dateOperation: string;
    isOpen?: boolean;
    hasBadge?:boolean;
    isCourier?:boolean;
    isContainerShared?: boolean;
    currencyOperation: string;
    role?: string;
    handleClickCompare: (e: string) => void;
    handleClickFinishQuote: (e: string) => void;
    handkeClickItemData: (operationId: string, param1: string, param2: string) => void;
 
} 

export const DashboardElements: React.FC<DashboardElementsProps> = ({
    id,
    operationId,
    numOperation,
    importationTitle,
    type,
    dataImportation,
    dateOperation,
    isOpen=false,
    hasBadge=false,
    isCourier=false,
    isContainerShared=false,
    currencyOperation,
    role="dashboard-element",
    handleClickCompare,
    handleClickFinishQuote,
    handkeClickItemData
}) => {

    const [expanded, setExpanded] = useState<boolean>(false);
    const [showCompare, setShowCompare] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(true);

    const [columnTitle, setColumnTitle] = useState<string[]>([]);
    const [columnData, setColumnData] = useState<ColumnTableResponsiveInterface[]>([]);

    // method to get colunm title if type and isCourier
    const setColumnsTitle = () => {
        const isWideScreen = window.innerWidth > 691;
    
        // Columnas comunes dependiendo de isContainerShared
        const commonColumns = isContainerShared
            ? ["Agencia", "Recibe hasta", "Llegada estimada", "Entregada", "Importe total"]
            : (isCourier
            ? ["Courier", "Calificación", "Cotización"]
            : ["Agencia", "Calificación", "Cotización"]);
    
        const commonColumnsWithMessage = 
            isContainerShared ? [...commonColumns]: 
            ( isCourier ? [...commonColumns, "Último mensaje"]: [...commonColumns, "Último mensaje"] );
    
        if (type === "requestQuote") {
            if (isWideScreen) {
                setColumnTitle(hasBadge ? ['Proveedor', 'Importe', 'Cliente'] : commonColumnsWithMessage);
            } else {
                setColumnTitle(commonColumns);
            }
        } else {
            if (isWideScreen) {
                setColumnTitle(hasBadge ? ['Proveedor', 'Orden', 'Importe', "Último mensaje"] : 
                (isContainerShared ? [...commonColumns] : 
                    [...commonColumns.slice(0, 2), "Orden", "Importe total", "Último mensaje"]));
            } else {
                setColumnTitle(isContainerShared ? ["Agencia", "Llegada estimada", "Importe total"] : 
                    [...commonColumns.slice(0, 1), "Orden", "Importe total"]);
            }
        }
    };

    // method to set data for table
    const setColumnsData = () => {
        setLoading(true);
        let columns: ColumnTableResponsiveInterface[] = [];
        dataImportation.map(async(d: operationRequestQuotes | operationOrders) => {
            const company = await getServiceByCompanyId(d.companyId);
            let data: any = {
                id: d.id,
                Proveedor: d.companyName,
                Courier: d.companyName,
                Agencia: d.companyName,
                Orden: "#" + d.number,
                [type === "requestQuote" ? "Importe" : "Importe total"]: `${currencyUtils(currencyOperation)} ${ConverMoneyFormat(d.price.toString())}`,
                Cliente: d.companyName,
                ['Calificación'] : (
                    <div className="rating-container"> 
                        <img src={`${STORAGE_ASSETS}/images/svg/icono_rating.svg`} alt="rating" />
                        {company ? (company.rating as number).toFixed(1) : 'N/A'}
                    </div>
                ),  
            };
            if (type === "requestQuote") {
                data["quotationId"] = 'quotationId' in d ? d.quotationId : "";                
                data["Cotización"] = d.price === 0 ? 'En proceso' : `${currencyUtils(currencyOperation)} ${ConverMoneyFormat(d.price.toString())}`;
                data["Último mensaje"] = window.innerWidth > 690 ? (d.lastMessage || "Todavía no hay ningún mensaje") : "";
            } else if(type === "order") {    
                data["Recibe hasta"] = FormatDate((d as operationOrders).receiveDate?.toDate() || new Date());
                data["Llegada estimada"] = FormatDate((d as operationOrders).arrivalDate?.toDate() || new Date());
                data["Entregada"] = (d as operationOrders).deliveryAddress;
                data["Último mensaje"] = window.innerWidth > 690 ? (d.lastMessage || "Todavía no hay ningún mensaje") : "";
            }
            columns.push(data);
        });
        setColumnData(columns);
        setTimeout(() => {
        setLoading(false);
       }, 800);
    }

    const handleQuoteItemClick = (requestQuoteId: string | undefined, quotationId: string | undefined) => {
        console.log("🚀 ~ handleQuoteItemClick:", requestQuoteId, quotationId);
        handkeClickItemData(operationId, requestQuoteId || "", quotationId || "unknown");
    }

    const handleOrderItemClick = (orderId: string, orderStep: string) => {
        handkeClickItemData(operationId, orderId, orderStep);
    }

    const getServiceByCompanyId = async (companyId: string) => {
        try {
            const company = await GetServiceByCompanyId(companyId);
            if(company.length > 0) {
                return (company[0]);
            } return false
        } catch (error) {
            console.log("🚀 ~ getServiceByCompanyId ~ error:", error) 
        }
    }

    // Función sencilla para convertir la fecha
    const convertDate = (dateString: string) => {
        const [day, month, year] = dateString.split("/");
        return `${day}/${month}/${year.slice(-2)}`;
    };

    useEffect(()=> {
        setExpanded(isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (type === "requestQuote" && dataImportation.length > 0) { 
            setShowCompare((dataImportation as any).filter((r: any) => r.price > 0).length >= 2);
        }
        setColumnsTitle();
        setColumnsData();
    }, [dataImportation]);

    return (
        <>
            {!loading && <div className="dashboardElements-container box-shadow-card" role={role}>
                <div onClick={()=> setExpanded(!expanded)} className={`dashboardElements-header ${!expanded && "dashboardElements-header__border"}`} >
                    <div className="dashboardElements-text">
                        <ArkabiaOp />
                        <div className="dashboardElements-title">
                            <div className="smalltext-header dashboardElements__title">{importationTitle}</div>
                            {/* <div className="smalltext dashboardElements__detail">{category}</div> */}
                        </div>
                    </div>
                    <div className="dashboardElements-right">
                        <div className="dashboardElements-date">
                            {/* <div className="tinytext">En cotización</div> */}
                            {/* { badge ? null : <CargoStatus phase={cargoStatus} role='cargo-status-dashboard'/> } */}
                            {/* {(order && window.innerWidth > 690) && <CargoStatus phase={cargoStatus} role='cargo-status-dashboard'/>} */}
                            <div className="tinytext-header dashboardElements-date-text">{window.innerWidth < 901 ? convertDate(dateOperation) : dateOperation}</div>
                            { hasBadge ? <Badge number={numOperation} /> : null}
                        {expanded?<ArrowUp /> :<ArrowDown />}
                        </div>
                    </div>
                </div>
                {expanded && <div className="dashboardElements-table">
                    {type === "requestQuote" && 
                        <div className="dashboard-elements-title">
                            <TableResponsive 
                                columns={columnTitle} 
                                data={columnData} 
                                handleClick={handleQuoteItemClick}
                                operationStatus="quote"
                                footer={(columnData.length === 0 ?
                                    <div className="smalltext dashboardElements-quote" onClick={()=>handleClickFinishQuote(id)} role='btn-dashboardElements'>
                                        Terminar solicitud
                                    </div> : ((showCompare &&  !isCourier) ?
                                    <div className="smalltext dashboardElements-quote dashboardElementsRow-container" onClick={()=>handleClickCompare(id)} role='btn-dashboardElements'>
                                        Comparar cotizaciones
                                    </div> : null))
                                }
                            />
                        </div>
                    }
                    {type === "order" && columnData.length>0 && <div className="dashboardElements-title">
                        <div className="dashboard-elements-title">
                            <TableResponsive 
                                columns={columnTitle} 
                                data={columnData} 
                                operationStatus="order"
                                handleClick={handleOrderItemClick}
                            />
                        </div>
                    </div>}                    
                </div>}    
            </div>}
        </>
    )
}
