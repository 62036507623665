import { FREQUENTLY_QUESTIONS, PLAN, QUOTATION_MULTIPLE, TOOLS } from "./BaseUrl";

export const SubMenuHome = [
    {
        name: "Soluciones",
        event: "",
        items: [
            {
                name: "Cotización múltiple",
                describe: "Compara los precios de distintas agencias usando IA",
                event: "/"+QUOTATION_MULTIPLE,
            },
            {
                name: "Ghost Importer",
                describe: "Haz dinero rápido importando para otras personas",
                event: "/"+TOOLS,
            },
        ]
    },
    {
        name: "CLUB",
        event: "https://www.skool.com/arkabia",
    },
    {
        name: "Precio",
        event: "/"+PLAN,
    },
    {
        name: "Preguntas frecuentes",
        event: "/"+FREQUENTLY_QUESTIONS,
    }
];