import React, { useEffect, useRef, useState } from "react";

import { useNavigate} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { getAuth, onAuthStateChanged } from "firebase/auth";

// Importando componentes
import { SignInBtn } from "./SignInBtn/SignInBtn";
import { Subheader } from "../Subheader/Subheader";
import { UserActive } from "../UserActive/UserActive";
import { Status } from "../Status/Status";
import { SectionNavbar } from "../SectionNavbar/SectionNavbar";
import { ButtonIcon } from "../ButtonIcon/ButtonIcon";
import { Button } from "../Button/Button";

// Importando iconos
import { HelpCircleBlack } from "../../icons/outline/HelpCircleBlack";
import { Logout } from "../../icons/outline/Logout";
import { LogoArkabiaYellow } from "../../icons/outline/LogoArkabiaYellow";


// Importando servicios
import authService from "../../Services/Auth.service";

// Importando constantes
import { PROFILE, CHAT,  MOBILE, INFORMATION, OPERATIONS, STORAGE_ASSETS, EMPRESA, MY_SUBSCRIPTION, RECEIPTS } from "../../Consts/BaseUrl";


// Importando Slice
import { GetUserByUID } from "../../Slices/User";

// Store
import type { AppDispatch } from "../../Store/Store";

// Importando estilo
import "./Navbar.css";
import { NotificationComponent } from "../NotificationComponent/NotificationComponent";
import { onMessageListener } from "../../firebaseConfig/FirebaseConfig";
import { NotificationInterface, PushNotificationInterface } from "../../interfaces/NotificationInterface";
import { toast } from "react-toastify";
import { getNotificationByUserId } from "../../Services/Notification.service";
import { IconNotificationBadget } from "../IconNotificationBadget/IconNotificationBadget";
import { IconMessageBadget } from "../IconMessageBadget/IconMessageBadget";
import { PushNotification } from "../Modal/ModalNotification/pushNotification/pushNotification";
import { GetNumChatsByUserId } from "../../Services/Chat.service";
import { IconAnimation } from "../IconAnimation/IconAnimation";
import { PanelContent } from "../PanelContent/PanelContent";
import { Tutorial } from "../Tutorial/Tutorial";
import { ReviewsConst } from "../../Consts/Reviews";
import { ModalAnimation } from "../Modal/ModalAnimation/ModalAnimation";
import { TutorialInterface } from "../../interfaces/TutorialInterface";
import { GetAllServicesHover } from "../../Services/ServiceType.service";
import { ServiceTypeInterface } from "../../interfaces/Service";
import { setAuth } from "../../Slices/Auth";
import { Hamburguer } from "../../icons/outline/Hamburguer";
import { SubMenuHome } from "../../Consts/subMenuHome";
import { HamburgerMenu } from "../../icons/solid/HamburgerMenu";
import { Menu } from "../Menu/Menu";
import { HamburgerGrayMenu } from "../../icons/solid/HamburgerGrayMenu";
import { Profile } from "../../icons/outline/Profile";
import { Money } from "../../icons/outline/Money";
import { CreditCard } from "../../icons/outline/CreditCard";
import { ProfileBlack } from "../../icons/outline/ProfileBlack";
import { MoneyBlack } from "../../icons/outline/MoneyBlack";
import { CreditCardWhite } from "../../icons/outline/CreditCardBlack";
import { UpDropdown } from "../../icons/outline/UpDropdown";
import { DownDropdown } from "../../icons/outline/DownDropdown";
import { DownDropdownWhite } from "../../icons/outline/DownDropdownWhite";


interface NavbarProps {
  subMenu: {
    title: string;
    event: ()=> void;
  }[],
  menuActive: number;
  classNameItem?: string;
  setMenuActive: React.Dispatch<React.SetStateAction<number>>;
  onClickLognIn?: () => void;
  onClickRegister?: () => void;
  setMobileActive?: React.Dispatch<React.SetStateAction<boolean>>; 
  isHome: boolean;
}

export const Navbar: React.FC<NavbarProps> = ({
  subMenu,
  menuActive,
  classNameItem,
  setMenuActive,
  onClickLognIn=()=>{},
  onClickRegister=()=>{},
  setMobileActive = ()=>{},
  isHome = false
}) => {

  let navigation = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  const {userDetail} = useSelector((state: any) => state.user);
  const {isLoggedIn} = useSelector((state: any) => state.auth);

  const [tutorialDetail, setTutorialDetail] = useState<TutorialInterface>();
  const [listTutorial, setListTutorial] = useState<TutorialInterface[]>(ReviewsConst);
  const [showTutorial, setShowTutorial] = useState<boolean>(false);
  const [showTutorialResponsive, setShowTutorialResponsive] = useState<boolean>(false);
  const [showItems, setShowItems] = useState<boolean>(false);

  const [open, setOpen] = useState<boolean>(false);
  // const [item, setItem] = useState<boolean>(true);
  const [showNotification, setShowNotification] = useState(false);  
  const [chatActive, setChatActive] = useState<boolean>(false);
  const [showAnimation, setShowAnimation] = useState<boolean>(false);
  
  // Estado para controlar el índice del submenú activo
  const [activeSubMenu, setActiveSubMenu] = useState(-1);

  const [loading, setLoading] = useState<boolean>(true);
  const [numMessages, setNumMessages] = useState<number>(0);

  // use state list of notifications
  const [notification, setNotification] = useState<NotificationInterface[]>([]);
  // use state to show push notifications sended from FCM
  const [pushNotification, setPushNotification] = useState<PushNotificationInterface>();

  const refModalAnimation = useRef<HTMLDivElement>(null);
  const refModalItem = useRef<HTMLDivElement>(null);

  // useState user
  // const [user, setUser] = useState(() => {
  //   const user = getAuth().currentUser;
  //   return user;
  // });

  const [serviceAllType, setServiceAllType] = useState<ServiceTypeInterface[]>([]);

  const handleMenuClick = ()=> {
    console.log("🚀 ~ file: Navbar.tsx ~ line 108 ~ handleMenuClick")
    setMobileActive(false);
    setMenuActive(-1);
    navigation(`/${!isLoggedIn ? "" : OPERATIONS}`, {replace: true});
  }

  const handleProfileClick = ()=> {
    setMobileActive(false);
    navigation("/"+PROFILE);
    setChatActive(false);
  }

  const handleMessageClick = ()=> {
    setMobileActive(false);
    setChatActive(true);
    navigation("/"+CHAT);
    
  }

  const handleLogout = async () => {
    const response = await authService.LogOutUser();
    if(response) {
        window.location.href = window.location.origin+'/';
    }
  }

  const handleWhatsapp = () => {
    navigation("/"+ CHAT);
    //window.open(`https://api.whatsapp.com/send/?phone=51959340149&text=¡Hola! Me gustaría hablar con un asesor.`)
    // window.open(`https://api.whatsapp.com/send/?phone=51959340149&text=¡Hola! Mi nombre es ${userDetail.name} ${userDetail.lastName} y necesito ayuda`)
  }

  // method to que user by UID
  const getUserInfo = (UID: string) => {
    setLoading(true)
        dispatch(GetUserByUID(UID))
        .unwrap()
        .then(async (response) => {
          if(response) {
            dispatch(setAuth({
              isLoggedIn: true,
              displayName: response.name+" "+response.lastName,
              userId: response.id,
              uid: response.UID,
              imgProfile: response.photoUrl
          }));
            await getNotificationByUser(response.id);
            getNumMessagesUnRead(response.id);   
          } 
          setLoading(false);
        })
        .catch((error) => {
            console.log("🚀 ~ file: Orders.tsx ~ line 264 ~ getUser ~ error", error)
            setLoading(false);
        });
  }

  // method to get notification by user
  const getNotificationByUser = async (userId: string) => {
    try {
      getNotificationByUserId(userId, (response) => {
        setNotification(response);
      });
      
    } catch (error) {
      console.log("🚀 ~ file: Navbar.tsx:155 ~ getNotificationByUser ~ error:", error)
      toast.error("Hubo un error al obtener las notificaciones intentelo en uno minutos, gracias");
    }
  }

  // method to show login modal
  const handleClickLogin = () => {
    setOpen(false); 
    onClickLognIn()
  }

  const handleNotification = () => {
    setShowNotification(true)
  }
  

  // const redirectToService = (serviceTypeId: string) => {
  //   setOpen(false);
  //   setChatActive(false);
  //   navigation(`/${SERVICE}/${serviceTypeId}`);
  // }

  const getNumMessagesUnRead = (userId: string) => {
    GetNumChatsByUserId(userId, (response) => {
      // console.log("🚀 ~ file: Navbar.tsx:197 ~ GetNumChatsByUserId ~ response:", response)
      setNumMessages(response);
    });
  }

  const handlePushClick = () => {
    setPushNotification(undefined);
    if(pushNotification && pushNotification.webpush?.fcmOptions.link) {
      navigation(pushNotification.webpush?.fcmOptions.link);
    }
  }

  const handleItemTutorialResponsive = () => {
    setShowTutorialResponsive(!showTutorialResponsive);
  }

  const searchTutorial = (text: any) => {
    if(text.length > 0) {
      setListTutorial(ReviewsConst.filter(
        (r) => 
          r.title.toLowerCase().includes(text.toLowerCase()) ||
          r.description.toLowerCase().includes(text.toLowerCase())
      ))
    }
  }

  const handleItemTutorialClick = (tmp: TutorialInterface) => {
    setTutorialDetail({
      id: tmp.id,
      icon: tmp.icon,
      title: tmp.title,
      description: tmp.description,
      duration: tmp.duration,
      preview: tmp.preview,
      path: tmp.path,
    });
    setShowTutorial(true);
  }

  const handleAnimationClick = () => {
    setShowAnimation((prev)=> !prev);
    setChatActive(false);
  }

  const handleNotificationClick = () => {
    setShowNotification((prev)=> !prev);
    setChatActive(false);
  }

  const getServiceType = async () => {
    try {
      setLoading(true);
      const response = await GetAllServicesHover();
      if(response.length > 0) {
          setServiceAllType(response);
      }
      setLoading(false);
      } catch (error) {
          setLoading(false);    
          console.log(error);
      }
  }

  // method listener for notifications
  onMessageListener((payload) => {
    if(payload) {
      setPushNotification(payload);
    }
  });

  const handleItemClickSub = (item: any, idx: number) => {
    item.event();
    setMenuActive(idx);
    setChatActive(false); 
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  //const handleSubMenuToggle = (idx: any) => {
    // Alterna la visibilidad del submenú
    //setActiveSubMenu(prevIdx => (prevIdx === idx ? null : idx));
  //};

  const handleClickItemMenu = (idx: number, row: any) => {
    if (!row.items) {
      //validamos si es url o evento
      if(typeof row.event === 'string' && row.event.includes('http')) {
        window.open(row.event, '_blank');
      } else {
        navigation(row.event);
      }
      setOpen(false);
    } 
    setActiveSubMenu(idx);
    setShowItems(row.items && row.items.length > 0);
    setMenuActive(idx); 
  }

  console.log(showItems, activeSubMenu)


  useEffect(()=>{
    open ? window.document.body.style.overflow = 'hidden' : window.document.body.style.overflow = 'unset';
  },[open]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
        if (refModalAnimation.current && !refModalAnimation.current.contains(event.target)) {
           setOpen(false);
        }
      };
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
}, []);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (currentUser) => {
      if(currentUser){
        // setUser(currentUser);
        getUserInfo(currentUser.uid);
      }
    });
  },[]);

  useEffect(() => {
    if(menuActive === 4) {
      setShowNotification(true);
    } else {
      setShowNotification(false);
    }
  }, [menuActive]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
        if (refModalItem.current && !refModalItem.current.contains(event.target)) {
            // console.log("click")
            setShowItems(false);
        }
      };
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
}, []);

  useEffect(() => {
    getServiceType();
  }, []);


  return (
    <div className="navbar-root" role='navbar' ref={refModalAnimation}>
      <div className={open ? "navbar-open" : ( isHome ? "navbar-landing" : "navbar")}> 
        <div className="navbar-logo">
          <div className="navbar-content-option-mobile">
            <div className="navbar-title-container" onClick={handleMenuClick}>
              <img 
                id="navbar-logo--desktop" 
                src={`${STORAGE_ASSETS}/images/png/${isHome ? 'arkabia-logo-white (1).png' : 'logo_new_black.png'}`}
                alt="Arkabia, Agenciamiento de aduana y transporte internacional" 
                />
              <img id={`${isHome ? 'navbar-logo--mobile-home' : 'navbar-logo--mobile'}`}  src={`${STORAGE_ASSETS}/images/png/${isHome ? 'arkabia-logo-white.png' : 'arkabia-logo.png'}`} alt="Arkabia, Agenciamiento de aduana y transporte internacional" width={window.innerWidth<=772?'159px': '165px'} height={window.innerWidth<=772?'auto': '25px'}/>
            </div>
          </div>
          {isHome ? <HamburgerMenu className="navbar-mob-btn" onClick={()=>{setOpen(true); setMobileActive(true)}}/> :
          <HamburgerGrayMenu className="navbar-mob-btn" onClick={()=>{setOpen(true); setMobileActive(true)}}/>}
        </div>

        {!isLoggedIn && isHome &&  (
        <div className="navbar-menu-item">
        {SubMenuHome.map((s, idx) => (
          <div className="navbar-menu-item-column" key={idx}>
            <div className="navbar-menu-item-header" onClick={()=>handleClickItemMenu(idx, s)}>
              <Subheader
                active={idx === activeSubMenu}
                content={s.name}
                handleClick={() => {
                  //handleSubMenuToggle(idx); // Activa o desactiva el submenú
                  //handleClickItemMenu(idx, s);
                }}
                className={classNameItem}
                whiteMode
              />
              {s.items && <DownDropdownWhite />}
            </div>
  
            {/* Mostrar el submenú solo si es el que está activo */}
            {activeSubMenu === idx && showItems && (
            <div className="navbar-menu-sub" ref={refModalItem}>
              <div className="tinytext-header navbar-menu-sub-title">HERRAMIENTAS</div>
              
              <div className="navbar-menu-item-sub">
                {s.items && s.items.map((item, subIdx) => (
                  <div
                    key={subIdx}
                    className="paragraph navbar-menu-item--cursor"
                    onClick={() => {
                      navigation(item.event);
                      setActiveSubMenu(-1); // Opcional: cerrar el submenú al hacer clic en un ítem
                      setOpen(false);
                    }}
                  >
                    <div className="small-bold navbar-menu-item-white">{item.name}</div>
                    <div className="tinytext-header navbar-menu-item-white">{item.describe}</div>
                  </div>
                ))}
              </div>
            </div>)}
          </div>
        ))}
      </div> )}

        {!loading ? !isLoggedIn ? (
          <div className="navbar-login-container">
            <div className='navbar-login-container--buttons'>
              {/* {isHome ? <SignInBtn content="Iniciar Sesión" onClick={()=> onClickLognIn()} color="" className="navbar-login-btnLogin-IsHome" /> : <SignInBtn content="Iniciar Sesión" onClick={()=> onClickLognIn()} className="navbar-login-btnLogin-NotHome" />} */}
              <SignInBtn content="Iniciar Sesión" onClick={()=> onClickLognIn()} className="navbar-login-btnLogin-NotHome" />
              <div className={"navbar-content--button smalltext-header"} onClick={()=> onClickRegister()} >Probar ahora</div>
            </div>
          </div>
        ) : (
          <>
            <div className="navbar-items">
              {
                subMenu.length > 0 && subMenu.map((item, idx) => {
                  return (
                    <Subheader 
                      key={idx} 
                      active={idx === menuActive} 
                      content={item.title}
                      handleClick={()=>handleItemClickSub(item, idx)} 
                      className={classNameItem}
                      whiteMode={isHome}
                    />
                  )
                })
              } 
            </div>

           {userDetail && userDetail.id && <div className="navbar-options">
              <div className="navbar-user-container" >
                <div className="navbar-user" onClick={()=>handleProfileClick()}>
                  <span className={`paragraph-header navbar-options-name ${isHome ? 'navbar-options-name--white' : ''}`}>
                    {userDetail.name+' '+userDetail.lastName}
                  </span>
                  <UserActive 
                    userId={userDetail.id ? userDetail.id : ''}
                    userImg={userDetail.photoUrl ? userDetail.photoUrl : ''}
                    isConnected={isLoggedIn}
                    name={userDetail.name}
                    lastname={userDetail.lastName}
                  />
                </div>
                
                {/* {showOptions && 
                <div className="navbar-user-options">
                  <div className="paragraph navbar-user-options-colorText" onClick={()=>navigation(`/${PROFILE}`)}>Ver perfil</div>
                  <div className="paragraph navbar-user-options-colorText" onClick={()=>navigation(`/${LOGIN}`)}>Cerrar sesión</div>
                </div>} */}
              </div>
              {!isHome ?<div className="navbar-icons">
                <IconAnimation isActive={showAnimation} handleClick={handleAnimationClick}/>
                <IconMessageBadget isActive={chatActive} handleMessageClick={handleMessageClick} badget={numMessages}/>
                {/*<Plus onClick={handleServiceClick}/>*/}
                <div className='messageTopBar-user_main--imageIcon' onClick={handleNotificationClick}>
                  <IconNotificationBadget
                    isActive={showNotification}
                    badget={notification.filter(n => n.isNew).length}/>
              </div>
              </div> : '' }
            </div>}
          </>
        ) : null}
      </div>

      {/* mobile menu section */}
      { open && 
        <div className="navbar-content-mobile" id="navbar-content-mobile"
            onClick={()=>{
              // setOpen(false); 
              setMobileActive(false)}}>
          <div className={isLoggedIn ? "navbar-content" : "navbar-content-login"}>
            <div className="navbar-content-mobile-column">
              <div className="navbar-content-mobile-header">
                <img 
                  id="navbar-logo--desktop" 
                  src={`${STORAGE_ASSETS}/images/png/arkabia-logo-white (1).png`}
                  alt="Arkabia, Agenciamiento de aduana y transporte internacional" 
                />
                <img src={STORAGE_ASSETS+'/images/svg/close_big.svg'} alt="" onClick={()=>{setOpen(false)}} className="panel-close"/>
              </div>
              {/* header */}
              {isLoggedIn ?
                <div className="navbar-content-header">
                  <UserActive
                      userImg={userDetail.photoUrl?userDetail.photoUrl:''}
                      isConnected={true}
                      name={userDetail.name}
                      lastname={userDetail.lastName}
                      userId={userDetail.id}
                  />

                  <div className="navbar-content--row navbar-user-detail" 
                      //  onClick={()=>setItem(!item)}
                  >
                    <div className="navbar-content-description">
                      <div className="navbar-content-description-basic">
                        <div className="paragraph-header navbar-content-description-basic-name">{userDetail.name+" "+userDetail.lastName}</div>
                        {userDetail.position && userDetail.businessName && <div className="paragraph navbar-content-description-basic-lastname">{userDetail.position+" "+ "en" +" "+userDetail.businessName}</div>}
                        {!userDetail.position && userDetail.businessName && <div className="paragraph navbar-content-description-basic-lastname">{userDetail.businessName}</div>}
                      </div>
                      {userDetail.status ? <Status online={userDetail.status}/> : null}
                    </div>
                  </div>
                </div> 
                :
                <div className="navbar-content-header">
                  <div className="navbar-content-header-login">
                    <div className="navbar-content-header-first">
                      {/* <LogoArkabiaYellow/> */}
                      {/* <div className="paragraph-header navbar-content-header-text-login">Te damos la bienvenida a Arkabia</div> */}
                    </div>
                    {/* <div className="navbar-responsive-button">
                      <Button content="Registrar" color="black-75" onClick={()=>onClickRegister()} />
                      <Button content="Iniciar Sesión" color="yellow" onClick={()=>handleClickLogin()} />
                    </div> */}
                    <div className="navbar-content-header-items">
                      {SubMenuHome.map(((st, idx) => (
                        <div className="navbar-content-header-column" key={idx}>
                          <div className="navbar-content-header-column-item" >
                            <Subheader 
                              key={idx}
                              // active={idx ? false : true} 
                              content={st.name} 
                              handleClick={()=>handleClickItemMenu(idx, st)} 
                              className={classNameItem}
                            />
                            {st.items && <DownDropdownWhite />}
                          </div>
                          {/* Mostrar el submenú solo si es el que está activo */}
                            {showItems && <>
                            {activeSubMenu === idx && (
                              <div className="navbar-content-header-column-sub">
                                <div className="tinytext-header navbar-menu-sub-title">HERRAMIENTAS</div>
                                  
                                <div className="navbar-content-header-column-item-sub">
                                  {st.items && st.items.map((item, subIdx) => (
                                    <div
                                      key={subIdx}
                                      className="paragraph navbar-menu-item--cursor"
                                      onClick={() => {
                                        navigation(item.event);
                                        setActiveSubMenu(-1); // Opcional: cerrar el submenú al hacer clic en un ítem
                                        setOpen(false);
                                      }}
                                    >
                                      <div className="paragraph-header navbar-menu-item-white">{item.name}</div>
                                      <div className="tinytext-header navbar-menu-item-white navbar-content-header-column-item-sub_description">{item.describe}</div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                            </>}
                        </div>
                      )))}
                    </div>
                  </div>
                </div>
              }
            </div>

            {/* Body */}
            <div className={isLoggedIn ? "navbar-content-body" : "navbar-content-body-not-login"}>
            {isLoggedIn && 
              <>
                <SectionNavbar
                  icon={<ProfileBlack />}
                  section="Perfil"
                  handleSection ={()=>{setOpen(false); setMobileActive(false); navigation(MOBILE+"/"+PROFILE)}}
                />
               {userDetail.userType.includes("user") && <>
                <SectionNavbar 
                  icon={<MoneyBlack />}
                  section="Mi suscripción"
                  handleSection ={()=>{setOpen(false); setMobileActive(false); navigation(MOBILE+"/"+MY_SUBSCRIPTION)}}
                />
                <SectionNavbar
                  icon={<CreditCardWhite/>}
                  section="Mis recibos"
                  handleSection ={()=>{setOpen(false); setMobileActive(false); navigation(MOBILE+"/"+RECEIPTS)}}
                />
               </>
               }
                <SectionNavbar
                  imgSrc={STORAGE_ASSETS+"/images/svg/animation-icon.svg"}
                  section="Tutoriales"
                  handleSection = {()=>handleItemTutorialResponsive()}
                  direction
                  iconDirection = {showTutorialResponsive ? STORAGE_ASSETS+"/images/svg/up-vector.svg" : STORAGE_ASSETS+"/images/svg/down-vector.svg"}
                />
                {showTutorialResponsive && 
                  <div className="navbar-list-tutorial-detail">
                    {listTutorial.map((r) => ( 
                      <Tutorial 
                        tutorial={r} 
                        handleItemClick={()=>handleItemTutorialClick(r)}/>
                    ))}
                  </div>
                }
              </>
            }
            </div>

            {/* Footer */}    
            <div className={isLoggedIn ? "navbar-content-footer" : "navbar-content-footer-login"}>
              {/* <ButtonIcon 
                content="Ayuda" 
                onClick={()=>handleWhatsapp()} 
                Icon={<HelpCircleBlack />} 
                size="extra-small" 
                color="clear"
              /> */}
              {!isLoggedIn && <div className="navbar-responsive-button">
                <div className="navbar-responsive-button-login">
                  <Button content="Iniciar Sesión" color="yellow" onClick={()=>handleClickLogin()} />
                </div>
                <div className="navbar-responsive-button-register">
                  <Button content="Registrar" color="white" onClick={()=>onClickRegister()} />
                </div>
                </div>}
              {isLoggedIn && <ButtonIcon 
                content={"Cerrar sesión"} 
                onClick={()=>handleLogout()} 
                Icon={<Logout />} 
                size="extra-small" 
                color="clear"
              />}
            </div>
          </div>
        </div>
      }

      { showAnimation &&
        <PanelContent
          show={showAnimation}
          setShow={setShowAnimation}
          title="Tutoriales"
          detail=""
          search
          setTextSearch={(e:any)=>searchTutorial(e)}
        >
          {listTutorial.map((r) => ( 
            <Tutorial 
              tutorial={r} 
              handleItemClick={()=>handleItemTutorialClick(r)}/>
          ))}
        </PanelContent>
      }

      {showNotification && 
        <NotificationComponent
          notifications={notification}
          handleModalNotification={handleNotification}
          showModalRight={showNotification}
          setShowModalRight={setShowNotification}
        />
      }

      {pushNotification && 
        <PushNotification 
          title={pushNotification.notification.title}
          content={pushNotification.notification.body}
          setPushNotification = {setPushNotification}
          handleItemClick={()=> handlePushClick()}
          createAt={new Date()}          
        />}

       {showTutorial && <ModalAnimation
          show={showTutorial}
          setShow={setShowTutorial}
          animation={[
            { 
              path: tutorialDetail?.path ? tutorialDetail.path : '',
              title: tutorialDetail?.title ? tutorialDetail.title : '',
              image: tutorialDetail?.icon ? tutorialDetail.icon : '',
              description: tutorialDetail?.description ? tutorialDetail.description : '',
            }
          ]}
      />}
    </div>
  );
};
