
import React, { useState, useRef, useEffect } from "react";

import './ModalAuthNew.css';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../Store/Store";
import { LeftArrow } from "../../icons/outline/LeftArrow";
import MainLogin from "../LogIn/MainLogin/MainLogin";
import { AuthSteps, AuthSteps_item } from "../../Consts/AuthSteps";
import MainRegisterNew from "../MainRegisterNew/MainRegisterNew";
import { Loader } from "../Loader/Loader";
// importando componentes
import { User } from "../../interfaces/User";
import { RecoverAccount } from "../RecoverAccount/RecoverAccount";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { GetUserByFirebaseId, LinkUserToQuotation } from "../../Services/User.service";
// importando constantes
import { ACTION_MODAL_REQUEST, AUTH_LOGIN, AUTH_MODAL, AUTH_QUOTATION_LINK, AUTH_REGISTER, AUTH_REGISTER_CODE, AUTH_REGISTER_STEP, OPERATIONS, PLAN_CURRENCY_MODAL, PLAN_MODAL, PLAN_SECONDATY_MODAL, PROVIDER, SERVICE, STORAGE_ASSETS } from "../../Consts/BaseUrl";
import { GetUserByUID } from "../../Slices/User";
import { setAuth } from "../../Slices/Auth";
import { ModalTyC } from "../Modal/ModalTyC/ModalTyC";
import authService from "../../Services/Auth.service";
import { CardTypeService } from "../CardTypeService/CardTypeService";
import { GetQuotationDetail } from "../../Services/Quotation.service";
import { toast } from "react-toastify";
import { CreditCard } from "../CreditCard/CreditCard";
import { UnshiftString } from "../../utils/ShiftString";
import { GetPlanById } from "../../Services/PlanArkabia.service";
import { GetSecondaryPlanById } from "../../Services/SecondaryPlanArkabia.service";
import { CreateNewSuscription } from "../../Services/Subscription.service";
import { CardSummaryPay } from "../CardSummaryPay/CardSummaryPay";
import { PlanInterface } from "../../interfaces/PlansInterface";


interface ModalAuthNewProps {
}

export const ModalAuthNew: React.FC<ModalAuthNewProps> = () => {

    const navigation = useNavigate();
    const [searchParams] = useSearchParams();
    const location = useLocation();

    const dispatch = useDispatch<AppDispatch>();

    const mainLoginRef: HTMLInputElement | any = useRef<HTMLInputElement>(null);
    const mainregisterRef: HTMLInputElement | any = useRef<HTMLInputElement>(null);

    const { isLoggedIn, isProtectedRoute } = useSelector((state: any) => state.auth);
    const { userDetail } = useSelector((state: any) => state.user);

    // useState Loading
    const [loading, setLoading] = useState(false);
    const [showFullRegister, setshowFullRegister] = useState(false); // controla el modal de detalle de registro

    // useState Variables
    const [showModalTyC, setShowModalTyC] = useState<boolean>(false);

    // state to manage authStep  
    const [authStep, setAuthStep] = useState<AuthSteps_item>('MAIN_LOGIN_STEP');

    const [showAuth, setShowAuth] = useState<boolean>(false);
    // state to manage if user is regintering from email link
    const [quoteLink, setQuoteLink] = useState<string>('');

    // useState to manage secondary plan
    const [secondaryPlanId, setSecondaryPlanId] = useState<string>('');
    const [planSelected, setPlanSelected] = useState<PlanInterface>();

    // state to manage if is free plan
    const [isFreePlan, setIsFreePlan] = useState<boolean>(false);
    // useState to manage currency
    const [currency, setCurrency] = useState<"USD" | "PEN">("USD");
    // useState to manage token
    const [token, setToken] = useState<string>('');
    // useState to manage price
    const [price, setPrice] = useState<number>(0);

    const [user, setUser] = useState(() => {
        const user = getAuth().currentUser;
        return user;
      });

    // regiter variables
    const [fullRegister, setFullRegister] = useState<User>({
        name: '',
        lastName: '',
        userName: '',
        country: {
            alpha2Code: "PE",
            imgFlag: '',
            name: 'Perú',
        },
        email: '',
        typeIt: '',
        phone: undefined,
        itNumber: '',
        status: true,
        photoUrl: '',
        createdAt: new Date(),
        updatedAt: new Date(),
        phoneCode: "",
        phoneCodeFlag: "",
        userType: ['user'],
        isFirstImport: true,
        planId: '',
        subscriptionId: '',
        culqiCustomerId: '',
        authStep: 1,
        documentType: 'DNI',
    });
    const [password, setPassword] = useState<string>('');
    
    const ref = useRef(null);

    // method to return internal step
    const handleReturnclick = () => {
        const before = (authStep === "MAIN_LOGIN_STEP" )? mainLoginRef.current.handleReturnclick() : 
        authStep === "MAIN_REGISTER_STEP" ? mainregisterRef.current.handleReturnclick() : true;

        if(!before) handleCloseAuth(true);
    }

    // method to get title from step
    const getTitleStep = () => {
        return AuthSteps.find( a => a.step === authStep)?.title
    }

     // metodo para recuperar informacion del usuario
    const getUser = async (UID: string) => {
        setLoading(true)
        dispatch(GetUserByUID(UID))
        .unwrap()
        .then(async (response) => { 
           if(response) {
            const plan = await GetPlanById(response.planId);
            setPlanSelected(plan);
                dispatch(setAuth({
                    isLoggedIn: true,
                    displayName: response.name+" "+response.lastName,
                    userId: response.id, 
                    uid: UID,
                    imgProfile: response.photoUrl
                }));
             
           }
            // console.log("🚀 ~ file: ProtectedRouter.tsx ~ line 46 ~ .then ~ response", response);
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }

    // getCurrentUser 
    const getCurretnAuthUser = async () => {
        return new Promise((resolve, reject) => {
            try {
                const auth = getAuth();
                onAuthStateChanged(auth, (userCurrent) => {
                    resolve(userCurrent);     
                });
            } catch (error) {
                console.log("🚀 ~ file: AdminPage.tsx:466 ~ returnnewPromise ~ error:", error);
                reject(error);
            }
        });
    }

    // method to process if user is regintering from email link and process seach params
    const processNavigation = async (userId: string) => {
        console.log("🚀 ~ file: Auth.tsx:113 ~ processNavigation ~ userId", userId)
        setLoading(true);
        if(quoteLink.length>0 && userId.length>0) {
          try {
            const response = await LinkUserToQuotation(userId, quoteLink);
          } catch (error) {
            console.log("🚀 ~ file: Auth.tsx:113 ~ processNavigation ~ error:", error)
            return;
          }
        }
        
        let search = searchParams.get(ACTION_MODAL_REQUEST) ? "?"+ACTION_MODAL_REQUEST+"="+searchParams.get(ACTION_MODAL_REQUEST) : '';

        search = search + (searchParams.get('serviceAction') ? (searchParams.get('serviceAction') === 'openChat' ?  '?serviceAction=showChat' : '') : '');

        setShowAuth(false);
        setTimeout(() => {
            navigation(location.pathname+search, {replace: true});
        }, 1500);
        setLoading(false);
    }

    // method to redirecto after login according search params
    const redirectToPath = async () => {
        console.log("ModalAuthNew -> redirectToPath")
        setShowAuth(false);
        if(searchParams.get('noRedirect') && searchParams.get('noRedirect') === 'true') {
            processNavigation('');
        } else {
            let urlLocation = (location.pathname).split('/'); // parseando url por "/"
            const user:any = await getCurretnAuthUser();

            if(user) {
                let userDetail = await GetUserByFirebaseId(user ? user.uid: ''); // obteniendo datos del usuario
                switch (urlLocation.length) {
                    case 2: 
                        if(urlLocation[0].length===0 && urlLocation[1].length===0) {
                            navigation(`/${userDetail.userType.includes("user") ? OPERATIONS : PROVIDER}`, {replace: true});
                        } else {
                            navigation("/"+OPERATIONS, {replace: true});
                        }
                        break;
                    case 4:
                        if(urlLocation[1]===SERVICE) {
                            // if(searchParams.get("showModal") && searchParams.get("showModal")==='login') {
                            //     navigation(`${location.pathname}${userDetail&&userDetail.userType.includes("user")?"/?showModal=quote" :"" } `);
                            // } else {
                                navigation(`${location.pathname}`);
                            // }
                        }
                        break;
                    
                    default:
                        navigation(`/${OPERATIONS}`);
                        break;
                } 
            }
        }
    } 

    const modalTyC = async () => {
        setShowAuth(false);
        setShowModalTyC(true);
        // console.log('aqui')
    }

    const modalTyCClose = async () => {
        setShowAuth(true);
        setShowModalTyC(false)
    }

    // handle close modal auth 
    const handleCloseAuth = async (logout: boolean) => {
      
        setShowAuth(false);
        logout && isProtectedRoute ? navigation("/") : navigation(location.pathname);
        
        dispatch(setAuth({isProtectedRoute: false}));

        if(user && !isLoggedIn && authStep === "MAIN_REGISTER_STEP" && logout) {

            const response = await authService.LogOutUser();
            dispatch(setAuth({
                isLoggedIn: false,
                displayName: "",
                imgProfile: "",
            }));
            window.location.reload();
        }
    }

    // method to validate register link from email
    const validaLink = async (quotationId: string) => {
        // console.log("🚀 ~ file: Auth.tsx:177 ~ validaLink ~ quotationId:", quotationId)
        setLoading(true);
        const response:any = await GetQuotationDetail(quotationId);
        // console.log("🚀 ~ file: Auth.tsx:180 ~ validaLink ~ response:", response)
        if(response && response.quoteGenerated && response.sendEmail.length=== 0) {
            toast.error("El enlace ya fue usado");
            setTimeout(() => {
                window.location.replace('https://arkabia.com');
            }, 2000);
        }
        setLoading(false);
    }

    // method to validate planId, secondaryId, price and currency
    const validaPlanData = async (planId: string, secondaryId: string, currency: string, registerStep: number) => {
        try {
            setLoading(true);
            const plan = await GetPlanById(planId);
            // console.log("🚀 ~ validaPlanData ~ plan:", plan);
            setPlanSelected(plan);
            if(plan && plan.id) {
                setFullRegister((prev:any) => ({
                    ...prev,
                    planId: planId,
                    subscriptionId: "",
                    authStep: registerStep,
                }));
                setCurrency(currency as "USD" | "PEN");
                setIsFreePlan((currency === "USD" ? plan.priceUsd : plan.pricePen) === 0);
                if(secondaryId.length>0 && secondaryId !==  "0") {
                    // validate secondary plan
                    const secondaryPlan = await GetSecondaryPlanById(secondaryId);
                    // console.log("🚀 ~ validaPlanData ~ secondaryPlan:", secondaryPlan);
                    if(secondaryPlan.planId === plan.id && planId) {
                        setLoading(false);
                        setSecondaryPlanId(secondaryId);                
                        setShowAuth(true);
                        setPrice(secondaryPlan.price);
                    } else {
                        setLoading(false);
                        toast.error("Error al validar el plan - cupon");
                        handleCloseAuth(true);
                    }
                } else {
                    // validate plan
                    setLoading(false);                    
                    setShowAuth(true);
                    setPrice(currency === "USD" ? plan.priceUsd : plan.pricePen);
                }
            } else {
                setLoading(false);
                toast.error("Error al obtener datos el plan");
                handleCloseAuth(true);
            }
           
           setAuthStep(AuthSteps[registerStep].step);

        } catch (error) {
            console.log("🚀 ~ validaPlanData ~ error:", error);
            toast.error("Error al validar el plan");
            handleCloseAuth(true);
        }     
    }

    const createSubscription = async (userId: string, token: string) => {
        setLoading(true);
        CreateNewSuscription({
            toke: token,
            userId: userId,
            planId: fullRegister.planId,
            secondaryPlanId: secondaryPlanId,
            currency: currency
        }).then((response) => {
            // console.log("🚀 ~ createSubscription ~ response:", response);
            toast.success("Suscripción creada correctamente", {autoClose: 5000});
            setTimeout(() => {
                setLoading(false);
                setShowAuth(false);
                navigation("/"+OPERATIONS);
            }, 500);
        }).catch((error) => {
            setLoading(false);
            console.log("🚀 ~ createSubscription ~ error:", error)
            toast.error("Error al crear la suscripción");
        });       
    }

    // handle token
    const handleToken = (token: string) => {
        setToken(token);
        createSubscription(userDetail.id, token);
    }


    // method to get secondary plan by id
    const getSecondaryPlanById = async (id: string) => {
        try {
            const secondaryPlan = await GetSecondaryPlanById(id);
            if(secondaryPlan && secondaryPlan.id) {
                setSecondaryPlanId(secondaryPlan.id);
                setPrice(secondaryPlan.price);
            } else {
                toast.error("Error al obtener el plan secundario");
            }            
        } catch (error) {
            console.log("🚀 ~ getSecondaryPlanById ~ error:", error)
            toast.error("Error al obtener el plan secundario");
        }
    }

    // method to get plan by id
    const getPlayById = async  (id: string) => {
        try {
            const plan = await GetPlanById(id);
            if(plan) {
                setPlanSelected(plan);
            } else {
                toast.error("Error al obtener el plan");
            }            
        } catch (error) {
            console.log("🚀 ~ getPlayById ~ error:", error)
            toast.error("Error al obtener el plan");
        }
    }
    
    const handleStartNowClick = async (planId: string, secondaryPlanId: string, currency: string) => {
        console.log("🚀 ~ handleStartNowClick ", planId, secondaryPlanId, currency);
        getSecondaryPlanById(secondaryPlanId);
    }

    useEffect(() => {
        setLoading(true);
        if(searchParams.get(AUTH_MODAL) && searchParams.get(AUTH_MODAL) === AUTH_REGISTER && searchParams.get(PLAN_MODAL) &&
        searchParams.get(PLAN_SECONDATY_MODAL) && searchParams.get(PLAN_CURRENCY_MODAL) && searchParams.get(AUTH_REGISTER_STEP)) {
            // obteniendo planId, secondaryPlanId y currency
            const planId = UnshiftString(searchParams.get(PLAN_MODAL)?.toString() || "", 12);
            const secondaryPlanId = UnshiftString(searchParams.get(PLAN_SECONDATY_MODAL)?.toString() || "", 12);
            const currency = UnshiftString(searchParams.get(PLAN_CURRENCY_MODAL)?.toString() || "", 12);
            const registerStep = parseInt(UnshiftString(searchParams.get(AUTH_REGISTER_STEP)?.toString() || "0", 12));

            validaPlanData(planId, secondaryPlanId, currency, registerStep);
        } else if(searchParams.get(AUTH_MODAL) && searchParams.get(AUTH_MODAL) === AUTH_LOGIN) {
            setAuthStep("MAIN_LOGIN_STEP");
            setShowAuth(true);
        } 
        // obteniendo email de parametros
        let emailFromParams = searchParams.get('email'); 
        if(emailFromParams && emailFromParams?.length>0) {
            setFullRegister({...fullRegister, email: emailFromParams}); 
        }

        // obteniendo codigo de registro
        if(searchParams.get(AUTH_REGISTER_CODE)){
            let registerCode = searchParams.get(AUTH_REGISTER_CODE);
            if(registerCode && registerCode.length>0) {
                setFullRegister((prev:any) => ({
                    ...prev,
                    registerCode: registerCode,
                }))
            }
        }

        // verificando quotationLink
        let quotationLink = searchParams.get(AUTH_QUOTATION_LINK)?.toString() ? searchParams.get(AUTH_QUOTATION_LINK)?.toString() : '';
        if(quotationLink && quotationLink.length>0) {
            setQuoteLink(quotationLink);
            validaLink(quotationLink);
        }
        
        setLoading(false);
    }, [searchParams]); 

    useEffect(()=>{
        if(showAuth) { 
           window.document.body.style.overflow = 'hidden';
           const auth = async() => {
                const userCurrent: any = await getCurretnAuthUser();
                if(userCurrent) {
                    // register notification
                    setUser(userCurrent);   
                    getUser(userCurrent.uid); 
                }          
            } 
        } else {
            window.document.body.style.overflow = 'unset';
        }
    },[showAuth]);

    useEffect(() => {
        if(isLoggedIn && userDetail && userDetail.id && userDetail.authStep === 3) {
            getPlayById(userDetail.planId); 
        }
    }, [userDetail]);


    return (
        <>
            {showAuth &&
                <div className="ModalRegisterNew" ref={ref}>
                    <div className="ModalRegisterNew-container">
                        <div className="ModalRegisterNew-header" onClick={()=>handleCloseAuth(false)}>
                            <img src={STORAGE_ASSETS+"/images/svg/logo-black-arkabia.svg"} alt="Agencia de aduana"/>
                        </div>

                        <div className="ModalRegisterNew-content-all">
                            {authStep !== "SELECT_SITUATION" && <div className='ModalRegisterNew-prevButton' onClick={()=>handleReturnclick()}>
                                <LeftArrow/>
                                <div className="paragraph">Regresar</div>
                            </div>}

                            <div className={authStep !== "MAIN_REGISTER_PAY" ? "ModalRegisterNew-body" : "ModalRegisterNew-body-pay"}>
                                <div className="ModalRegisterNew--title mega-header">{getTitleStep()}</div>
                                { !loading ?
                                <>
                                    { authStep === "MAIN_LOGIN_STEP" ? 
                                    <MainLogin
                                        ref={mainLoginRef}
                                        emailParams={searchParams.get('email') || ''}
                                        setFullRegister={setFullRegister}
                                        setshowFullRegister={setshowFullRegister}
                                        setAuthStep={setAuthStep}
                                        redirectToPath={redirectToPath}
                                        processNavigation={processNavigation}
                                        handleCloseAuth={()=>setShowAuth(false)}
                                    /> :
                                    authStep === "MAIN_REGISTER_STEP" ? 
                                    <MainRegisterNew
                                        isFreePlan={isFreePlan}
                                        isEmailFromParams={searchParams.get('email') ? true : false}
                                        ref={mainregisterRef}
                                        fullRegister={fullRegister}
                                        setFullRegister={setFullRegister}
                                        password={password}
                                        setPassword={setPassword}
                                        setDetailRegisterModal={setshowFullRegister}
                                        setAuthStep={setAuthStep}
                                        internalStep={showFullRegister?1:0}
                                        closeAuthModal={()=>setShowAuth(false)}
                                        onlyCloseModal={()=>setShowAuth(false)}
                                        redirectToPath={redirectToPath}
                                        handleTyC={modalTyC}
                                        createSuscription={createSubscription}
                                    /> : 
                                    authStep ==='MAIN_REGISTER_PAY' ? 
                                    
                                    planSelected && <div className="ModalRegisterNew-body-summary"> 
                                        {/* AQUI VA CULQI */}
                                        <CreditCard
                                            price={price}
                                            email={fullRegister.email}
                                            currency={currency}
                                            onClick={handleToken}
                                        /> 
                                        <CardSummaryPay 
                                            planSelected={planSelected}
                                            currency={currency}
                                            handlePlanClick={handleStartNowClick}
                                            black={false}
                                        />
                                    </div>
                                    : authStep === "SELECT_SITUATION" ?
                                    <CardTypeService 
                                        onClose={()=>setShowAuth(false)}
                                        hardRedirect
                                    /> :
                                    authStep === "RESTORE_STEP" &&  
                                    <RecoverAccount email={fullRegister.email} setAuthStep={setAuthStep} />}
                                </> : <Loader/>
                                }
                            </div>
                        </div>
                    </div>
                    
                </div>
            }
            <ModalTyC setShowModal={modalTyCClose} showModal={showModalTyC}/>
        </>
    )
}