import React, { useState, useEffect, useCallback } from "react";

import {
  useNavigate,
  useParams,
} from "react-router-dom";
import { toast } from "react-toastify";

import { useDispatch, useSelector } from "react-redux";

import ReactGA from "react-ga4";

/** Importando componentes */
import { ButtonIcon, OperationHeader } from "../../../../../components";
import { Button } from "../../../../../components/Button/Button";
import { Multimedia } from "../../../../../components/Multimedia/Multimedia";
import { Orders } from "../../../../../components/Orders/Orders";
import { ImportTimelineSupplier } from "../../../../../components/ImportTimelineSupplier/ImportTimelineSupplier";
import { AbsoluteButton } from "../../../../../components/Button/Absolute/Absolute";
import { Chat } from "../../../../../components/Chat/Chat";
import { MessageTopbar } from "../../../../../components/MessageTopBar/MessageTopbar";
import { PaymentGateway } from "../../../../../components/PaymentGateway/PaymentGateway";
import { ModalComplete } from "../../../../../components/Modal/ModalComplete/ModalComplete";
import { Loader } from "../../../../../components/Loader/Loader";
import { Quote } from "../../../../../components/Quotation/Quote/Quote";
import { ButtonBadget } from "../../../../../components/ButtonBadget/ButtonBadget";
import { QuoteUnknow } from "../../../../../components/Quotation/Quote/QuoteUnknow";
import { ModalSendMessage } from "../../../../../components/Modal/ModalSendMessage/ModalSendMessage";
import { QuoteTransportData } from "../../../../../components/QuoteTransportData/QuoteTransportData";
import { QuoteCustomsData } from "../../../../../components/QuoteCustomsData/QuoteCustomsData";



// importando constantes
import { PhasesImportData } from "../../../../../Consts/phasesImport";
import { CHAT, DETAIL, OPERATION_ID, OPERATIONS, ORDER } from "../../../../../Consts/BaseUrl";

// Importanto interfaces
import { OperationInterface, operationRequestQuotes } from "../../../../../interfaces/Operation";
import { RequestQuoteInterface } from "../../../../../interfaces/RequestQuote";
import {  ChatInterface, CreateChatInterface } from "../../../../../interfaces/ChatInterface";
import { QuotationDB } from "../../../../../interfaces/Quotation";
import { Company } from "../../../../../interfaces/Company";

// Importando slice
import { GetOperationDetail } from "../../../../../Slices/Operation";
import { GetQuotationById } from "../../../../../Slices/Quotation";

// Importando store
import type { AppDispatch } from "../../../../../Store/Store";

//Importando utilidades
import { FormatDate } from "../../../../../utils/formatDate";

import { ImportTimelineStages } from "../../../../../@types/timelineStagesTypes";

// importando servicios
import { HireQuotation } from "../../../../../Services/Quotation.service";
import { GetCompanyById } from "../../../../../Services/Company.service"; 

// importando estilos
import "./OrdersQuotationDetailPage.css";

import { CreateNewChat, GetChatById } from "../../../../../Services/Chat.service";
import debounce from "lodash.debounce";
import { sendNotificationToUser } from "../../../../../Services/Notification.service";
import { GetRequestQuoteById, UpdateRequestQuote } from "../../../../../Services/RequestQuote.service";
import { sendProviderMessageNotification, sendUserMessageNotification } from "../../../../../Services/Whatsapp.service";
import { GetUserById } from "../../../../../Services/User.service";
import { MessageBlack } from "../../../../../icons/outline/MessageBlack";
import { HeaderCustomsPhases } from "../../../../../components/HeaderCustomsPhases/HeaderCustomsPhases";
import { EditWhite } from "../../../../../icons/outline/EditWhite";
import { ModalProcessService } from "../../../../../components/Modal/ModalProcessService/ModalProcessService";

export const OrdersQuotationDetailPage: React.FC<{}> = () => {

  let navigation = useNavigate();

  let params = useParams();

  const dispatch = useDispatch<AppDispatch>();

  const [showchat, setShowChat] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [edit, setEdit] = useState(true);
  const [showModalComplete, setShowModalComplete] = useState(false);

  const [phase, setPhase] = useState(1); // fase 0 chat
  const [loading, setLoading] = useState<boolean>(false);

  const [unknown, setUnknow] = useState<boolean>(false);

  const [fileProforma, setFileProforma] = useState<any[]>([]);
  const [filePackingList, setFilePackingList] = useState<any[]>([]);
  const [fileDataSheet, setFileDataSheet] = useState<any[]>([]);
  const [quoteSend, setQuoteSend] = useState<ImportTimelineStages>(2);
  const [operation, setOperation] = useState<OperationInterface>();
  const [requestQuoteOp, setRequestQuoteOp] = useState<operationRequestQuotes>();
  // const [companyQuote, setCompanyQuote] = useState<any[]>([]);
  const [showModalContact, setShowModalContact] = useState<boolean>(false);

  // obteniendo state de Redux
  const { operationList } = useSelector((state: any) => state.operation);
  const { quotationDetail } = useSelector((state: any) => state.quotation);
  // const { requestQuoteDetail } = useSelector(
  //   (state: any) => state.requestQuote
  // );
  const { userDetail } = useSelector((state: any) => state.user);

  // const [quotationDetail, setQuotationDetail] = useState<any>();
  const [company, setCompany] = useState<Company>({
    aboutUs: "",
    catProviderName: "",
    city: "",
    country: {
      alpha2Code: "",
      imgFlag: "",
      name: "",
    },
    createdAt: new Date(),
    name: "",
    numComments: 0,
    numOperation: 0,
    phone: "",
    photoCover: "",
    photoProfile: "",
    rating: 0,
    reviewServiceId: "",
    serviceType: "",
    status: false,
    updatedAt: new Date(),
    userAdmin: "",
    email: "",
    direction: "",
  });

  // use state controll modalQuote
  const [editRq, setEditRq] = useState<boolean>(false);

  /** declarando useState */
  /** state cotizacion */
  const [requestQuote, setRequestQuote] = useState<RequestQuoteInterface>({
    title: "",
    number: 0,
    user: {
      id: "",
      name: "",
      typeIt: "",
      itNumber: 0,
      numOperations: 0,
      phone: "",
      rating: 0,
    },
    companyName: "",
    serviceId: "",
    serviceTypeId: "",
    serviceTypeLabel: "",
    status: "draft",
    statusProvider: "No leída",
    active: true,
    customs: {
      regime: "Importación definitiva",
      incoterm: "",
      value: 0,
      coin: "",
      insurance: false,
      merchandisePermits: {
        dataSheet: [],
        proforma: [],
        packingList: [],
      },
      exwDirection: "",
      descriptionOperation: "",
    },
    transport: {
      type: "sea",
      origin: {
        city: "",
        country: "",
      },
      destination: {
        city: "",
        country: "",
      },
      typeContainer: {
        lcl: false,
        fcl: false,
        lclAir: false,
      },
    },
    phaseDraft: 1,
    operationId: "",
    numServiceComments: 0,
    numServiceRating: 0,
    observation: "",
  });

  // useStateChat
  const [chatdetail, setchatDetail] = useState<ChatInterface>();

  // useState search
  const [searchText, setSearchText] = useState<string>('');

  // handle click mesage
  const handleMessageClick = (chatId: string) => {
    setPhase(0);
    window.innerWidth <= 880 ? handleMessageMobileClick(chatId) : setShowChat(true);
    // console.log("showchat", showchat);
  }

 // methos to navigate chat mobile
  const handleMessageMobileClick = (id: string) => {
      navigation("/"+CHAT+"/"+DETAIL+"/"+id);
  }

  const handlePhaseClick = (phase: number) => {
    setPhase(phase);
    if (phase === 1) {
      setShowChat(false);
    }
  };

  const handlePayClick = () => {
    setShowPayment(false);
    setShowModalComplete(true);
  };

  // obtiene detalle de la operacion segun id de FireStore
  const getOperationDetail = async (operationId: string) => {
    setLoading(false);
    if (!loading) {
      setLoading(true);
      dispatch(GetOperationDetail(operationId))
        .unwrap()
        .then((response) => {
          if(response.userId === userDetail.id || response.authorizedUsers.includes(userDetail.id)) {
            getResQuoteResume(response);
            setOperation(response);
            setLoading(false);
          } else {
            setLoading(false);
            toast.error("No tienes permisos para ver esta operación");
            navigation("/"+OPERATIONS);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  // recupera el servicio de redux segun id
  const GetLocalOperationDetail = (id: string) => {
    return operationList.find((o: OperationInterface) => o.id === id);
  };

  // obteniendo cotizacion de fireStore
  const getQuotationDetail = async (id: string) => {
    setLoading(false);
    if (!loading) {
      setLoading(true);
      dispatch(GetQuotationById(id))
        .unwrap()
        .then(async (response) => {
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  // get requestQuote from firestore
  const getRequestQuote = async (id: string) => {
    setLoading(false);
    if (!loading) {
      setLoading(true);
      try {
        const response:RequestQuoteInterface = await GetRequestQuoteById(id);
        if(response) { 
            setRequestQuote(response);
            response.companyId !== requestQuote.companyId && getCompany(response.companyId ? response.companyId : "");
            if(!response.chatId || response.chatId !== requestQuote.chatId) {
              response.chatId && response.chatId.length> 0 ? getChat(response.chatId) : createChatToOperation(response);
            }
            if(response.customs.merchandisePermits) {
              setFileProforma(response.customs.merchandisePermits.proforma);
              setFilePackingList(response.customs.merchandisePermits.packingList);
              setFileDataSheet(response.customs.merchandisePermits.dataSheet);
            }
        }
      
      } catch (error) {
        console.log("🚀 ~ file: OrdersQuotationDetailPage.tsx:294 ~ getRequestQuote ~ error:", error)
        setLoading(false);
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const getCompany = async (id: string) => {
    if (!loading && id && id.length > 0) {
      // toast.info("Obteniendo datos...");
      try {
        setLoading(true);
        const response: any = await GetCompanyById(id);
        setCompany(response);
        setLoading(false);
      } catch (error) {
        console.log("🚀 ~ getCompany ~ error:", error);
      }
    }
  };

  //  obteniendo la operacion de Redux
  const getResQuoteResume = (op: OperationInterface) => {
    if (op && op.requestQuotes.length > 0) {
      let tmpRq: any = op.requestQuotes.find(
        (rq: any) => rq.id === params.requestQuoteId
      );
      setRequestQuoteOp(tmpRq);
      getRequestQuote(tmpRq.id);
    }
  };

  const handleConfirm = () => {
    // setLoading(true);
    // handleHireQuoteClick ()
    if(operation?.id !== OPERATION_ID) {
      setShowModalComplete(true);
    }  else {
      toast.warning("Cotizacion de prueba, no se puede contratar");
    }
  }

  // metodo para contrar una cotizacion
  const handleHireQuoteClick = async () => {
    if(!requestQuoteOp) return;

    setLoading(true);
    setShowModalComplete(false);
    const data: {
      requestQuoteId: string;
      operationId: string;
      quotationId: string;
      serviceId: string;
      title: string;
      type: string;
      price: string;
      serviceTypeId: string;
      serviceTypeLabel: string;
    } = {
      requestQuoteId: requestQuoteOp.id ,
      operationId: operation?.id ? operation.id : "",
      quotationId: quotationDetail.id ? quotationDetail.id : "",
      serviceId: requestQuoteOp.serviceId,
      title: operation?.title ? operation.title : "",
      type: operation?.type ? operation.type : "",
      price: quotationDetail.price ? quotationDetail.price.toString() : "",
      serviceTypeId: requestQuoteOp.serviceType,
      serviceTypeLabel: requestQuoteOp.serviceTypeLabel,
    };

    toast.success("Contratando servicio...");
    GA4_purchase(quotationDetail, requestQuote);
    HireQuotation(data)
      .then((response: any) => {
        // console.log(
        //   "🚀 ~ file: OrdersQuotationDetailPage.tsx:470 ~ HireQuotation ~ data:",
        //   data
        // );
        if (response) {
          // console.log("🚀 ~ file: OrdersQuotationDetailPage.tsx:362 ~ .then ~ response:", response)
          setLoading(false);
          toast.success("¡Servicio contratado!");
          // setShowModalComplete(true);
          // setShowModalContact(true);
          // window.open(`https://wa.me/${company.phone}?text=¡Felicidades! 🎉 @${userDetail.name} ha decidido trabajar con tu agencia. El número de operación es NRO ${response.number}`);
          setTimeout(() => {
            navigation(`/${OPERATIONS}/${data.operationId}/${ORDER}/${response}/1`)
          }, 2500);
        }
      })
      .catch((error) => {
        //console.log(error);
        toast.error("Hubo un error al reservar :(");
        setLoading(false);
      });
  };

  // metodo para actualizar RequestQuote
  const handleUpdateRq = async () => {
    setEditRq(false);
    toast.success("¡Se actualizaron los correctamente!");
    try {
      const response: any = await GetCompanyById(requestQuote.companyId ? requestQuote.companyId : "");
      if(response) {
        sendNotificationToUser({
          title: "¡Solicitud actualizada!",
          content: userDetail.name+" "+userDetail.lastName+" ha modificado los requerimientos",
          operationName: requestQuote.title,
          operationId: requestQuote.operationId ? requestQuote.operationId : "",
          userId: response.userAdmin,
          name: userDetail.name+" "+userDetail.lastName,
          image: userDetail.photoURL ? userDetail.photoURL : "",
          event: "editRQUser",
          urlPath: `${OPERATIONS}/${requestQuote.operationId}/${requestQuote.id}/cotizaciones/${quotationDetail.id ? quotationDetail.id: "unknown"}`
        }).then((response) => {
          // console.log("🚀 ~ file: OrdersQuotationDetailPage.tsx:389 ~ handleUpdateRq ~ response:", response)
        }).catch((err) => {
          console.log("🚀 ~ file: OrdersQuotationDetailPage.tsx:391 ~ handleUpdateRq ~ err:", err)
        }); 
      }
    } catch (error) {
      console.log("🚀 ~ file: OrdersQuotationDetailPage.tsx:398 ~ handleUpdateRq ~ error:", error)
    }
 
    setTimeout(() => {
      getOperationDetail(params.operationId ? params.operationId : "");
    }, 1500);
  };

  // method to get chat by id
  const getChat = (chatId: string) => {
    if(chatId.length>0) {
      GetChatById(chatId, (value)=>{
        // console.log(value);
        setchatDetail(value); 
      });
    }
  }

  const createChatToOperation = (rq: RequestQuoteInterface) => {
    // console.log("🚀 ~ createChatToOperation ~ rq:", rq)
    setLoading(true);
    const chatData: CreateChatInterface = {
      generatedFrom: "requestQuote",
      typeChat: 'group',
      userIdGenerated: userDetail.id,
      userTypeGenerated: userDetail.userType[0],
      recivedUserId: userDetail.id,
      receivedCompanyId: rq.companyId ? rq.companyId : "",
      operationName: rq?.title,
      operationId: rq?.operationId,
      operationType: rq?.category,
      operationPhase: "requestQuote",
      documentIdPhase: rq.id,
    }
    // console.log("🚀 ~ file: OrdersQuotationDetailPage.tsx:426 ~ createChatToOperation ~ chatData:", chatData);
    CreateNewChat(chatData).then(async (response:any) => {
      //console.log("🚀 ~ file: OrdersQuotationDetailPage.tsx:435 ~ CreateNewChat ~ response:", response)
      const respUpdate = await UpdateRequestQuote({
        id: rq.id,
        chatId: response,
        updatedAt: new Date(),
      });
      setRequestQuote((prev) => ({
        ...prev,
        chatId: response.id || ""
      }));
      getChat(response);
      setLoading(false);
     
      // await getRequestQuote(requestQuoteOp.id);
    }).catch((error) => {
      setLoading(false);
      console.log("🚀 ~ file: ServiceDetailPage.tsx:340 ~ CreateNewChat ~ error:", error)
      toast.error("Hubo un error al creat Chat, por favor intentelo mas tarde");
    });
  }
  
  // handle seach text change
  const handleSeachChange = useCallback(
    debounce((text)=> {
      setSearchText(text);
    }, 500), [searchText]);

  
  // method to send whatsapp notification to provider
  const sendProviderWspNotification = async () => {
    try {
        // find all user to send whatsapp notification
        const users = chatdetail ? chatdetail.members.filter((m) => (m.userId !== userDetail.id && !m.isCompany)) : [];
        // iterate users to send whatsapp notification
        users.length> 0 && users.forEach(async (user) => {
            // get user detail by id
            const respUser: any = await GetUserById(user.userId);
            // send whatsapp notification
            sendUserMessageNotification(respUser.phoneCode+""+respUser.phone, respUser.name + " " + respUser.lastName, chatdetail?.companyName ? chatdetail.companyName : "")
            .then((wsp) => {
                // console.log("🚀 ~ file: ChatPage.tsx:490 ~ .then ~ wsp:", wsp);
            }).catch((errorWsp) => {
                console.log("🚀 ~ file: ChatPage.tsx:492 ~ .then ~ error:", errorWsp);
            });      
        });
        
    } catch (error) {
        console.log("🚀 ~ file: ChatPage.tsx:488 ~ sendProviderWspNotification ~ error:", error);
    }
  }

  // method to send whatsapp notification to user
  const sendUserWspNotification = async () => {
      try {
          // find all user to send whatsapp notification
          const users = chatdetail ? chatdetail.members.filter((m) => (m.userId !== userDetail.id && m.isCompany)) : [];
          // iterate users to send whatsapp notification
          users.length> 0 && users.forEach(async (user) => {
              // get user detail by id
              const respUser: any = await GetUserById(user.userId);
              sendProviderMessageNotification(respUser.phoneCode+""+respUser.phone, userDetail.name + " " + userDetail.lastName, chatdetail?.companyName ? chatdetail.companyName : '')
              .then((wsp) => {
                  // console.log("🚀 ~ file: ChatPage.tsx:505 ~ .then ~ wsp:", wsp);
              }).catch((errorWsp) => {
                  console.log("🚀 ~ file: ChatPage.tsx: 507 ~ .then ~ error:", errorWsp);
              });
          });
      } catch (error) {
          console.log("🚀 ~ file: ChatPage.tsx:510 ~ sendUserWspNotification ~ error:", error)
      }
  }

  const sendWhatsappNotification =() => {
    try {
        userDetail.userType.includes('user') ? sendUserWspNotification() : sendProviderWspNotification();
    } catch (error) {
        console.log("🚀 ~ file: ChatPage.tsx:491 ~ debounce ~ error:", error);
    }
  }

  // Method to send begin_checkout event to GA4
  const GA4_purchase = (quotation: QuotationDB, requestQuote: RequestQuoteInterface) => {
    ReactGA.gtag("event", "purchase", {
      transaction_id: quotation.id,
      value: quotation.price,
      tax: quotation.taxes.subTotal,
      currency: "USD",
      items: [{
        item_id: requestQuote.id,
        item_name: requestQuote.title,
        item_brand: requestQuote.companyName,
        item_category: requestQuote.serviceTypeLabel,
        item_list_id: "services_"+requestQuote.serviceTypeId,
        item_list_name: "Services "+requestQuote.serviceTypeLabel,
        price: quotation.price,
        quantity: 1
      }]
    });
  }

  /** carga el state de operacion segun el id de la operacion de local o fireStore */
  useEffect(() => {
    if (params && params.operationId && params.requestQuoteId) {
      // recibe id de la operacion enviada por url params
      setLoading(true);
      const operation = GetLocalOperationDetail(params.operationId);
      if (operation) {
        setOperation(operation);
        // setCompanyQuote(operation.requestQuoteId);
        getResQuoteResume(operation);
      } else {
        getOperationDetail(params.operationId);
      }

      if (params.quotationId) {
        if (params.quotationId === "unknown") {
          setUnknow(true);
          setQuoteSend(1);
        } else {
          getQuotationDetail(params.quotationId);
        }
      }

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [params]);

  useEffect(() => {
    window.document.body.style.overflow = "unset";
  }, []);
  
  return (
    <>
      <OperationHeader
        operationDetail={operation}
        teams={[]}
        onClickHome={() => {}}
        updateOperation={() => getOperationDetail(operation?.id || "")}
        showDots = {userDetail.userType.includes('user') && !requestQuote.quoteGenerated} 
        />
      {/* <ToastContainer hideProgressBar autoClose={3000} pauseOnFocusLoss={false} pauseOnHover={false}  /> */}
      <div className="ordersQuotationDetailPage-container-header-mobile">
        {/* <GobackButton /> */}
        <ButtonBadget
          name={
              <MessageBlack/>
          }
          handleBadget={()=>handleMessageClick(chatdetail?.id?chatdetail.id:'')}
          badget={chatdetail ? chatdetail.members.find((member) => member.userId === userDetail.id)?.numMessagesUnread : 0}
        />
    </div>

      {!loading ? (
        <>
          {operation &&
          quotationDetail &&
          requestQuoteOp &&
          requestQuote && (
            <div className="ordersQuotationDetailPage-container">
              {/* <div className="ordersQuotationDetailPage-container-header-mobile">
                  {/* <GobackButton /> */}
                  {/* <ButtonBadget
                      name={
                          <MessageBlack/>
                      }
                      handleBadget={()=>handleMessageClick(chatdetail?.id?chatdetail.id:'')}
                      badget={chatdetail ? chatdetail.members.find((member) => member.userId === userDetail.id)?.numMessagesUnread : 0}
                  /> 
              </div> */}

              <div className="ordersQuotationDetailPage-container-row">
                {/** Aqui comienza el scroll del lado derecho */}
                <div className="ordersQuotationDetailPage-menu-scroll">
                  <div className="ordersQuotationDetailPage-menu-scroll-multimedia">
                    {chatdetail?.attachments && 
                      <Multimedia 
                        multimedia={chatdetail?.attachments} 
                        title=""
                        number={5}
                      />
                    }
                  </div>

                  <Orders
                    title="Órdenes"
                    serviceTypeLabel={requestQuoteOp.serviceTypeLabel
                    }
                    type="Cotización"
                    companyName={requestQuoteOp.companyName}
                    rating={requestQuoteOp.serviceRating}
                    typeOperation={operation.type}
                    order={{
                      num: requestQuoteOp.numRequestQuote,
                      numComments: requestQuoteOp.numServiceComments,
                      date: "",
                    }}
                    country={{
                      alpha2Code:
                        requestQuoteOp.country.alpha2Code.length > 0
                          ? requestQuoteOp.country.alpha2Code
                          : "PE",
                      imgFlag:
                        requestQuoteOp.country.imgFlag.length > 0
                          ? requestQuoteOp.country.imgFlag
                          : "https://firebasestorage.googleapis.com/v0/b/arkabia-mvp-v1.appspot.com/o/static%2Fimg%2Fflag_of_Peru.png?alt=media&token=d8bfda2c-4c48-419a-b8b9-00a423a24d53",
                      city:
                        requestQuoteOp.city.length > 0
                          ? requestQuoteOp.city
                          : "Lima",
                    }}
                    active={operation.active}
                    phases={PhasesImportData}
                    addOrder={true}
                    flagOrder={true}
                    blackModeOrder={true}
                    currency={quotationDetail.currency || "USD"}
                    price={unknown ? "-" : (quotationDetail.price || 0)}
                    onClickPhase={handlePhaseClick}
                    showButton={!unknown}
                    handleClick={handleConfirm}
                    numPhase={phase}
                  />
                </div>
                
                {/* * pegar codigo */}
                <div className="ordersQuotationDetailPage-chat">
                  {!showchat && (
                    <div className="customsAgency-chat-content">
                      <HeaderCustomsPhases
                          numPhase={phase}
                          title={PhasesImportData[phase-1]}
                          handleMessageclick={()=>{}}
                      />
                      {window.innerWidth > 990 ?
                      <ButtonBadget
                          name="Ver mensajes" 
                          handleBadget={()=>handleMessageClick(chatdetail?.id?chatdetail.id:'')}
                          badget={chatdetail ? chatdetail.members.find((member) => member.userId === userDetail.id)?.numMessagesUnread : 0}
                      /> : 
                      <ButtonBadget
                        name={
                            <MessageBlack/>
                        }
                        handleBadget={()=>handleMessageClick(chatdetail?.id?chatdetail.id:'')}
                        badget={chatdetail ? chatdetail.members.find((member) => member.userId === userDetail.id)?.numMessagesUnread : 0}
                      />}
                  </div>                    
                )}

                  <div className={"ordersQuotationDetailPage-content-chat"}>
                    {/** seccion mobile */}
                    {requestQuoteOp && <div className="ordersQuotationDetailPage-mobile--orders">
                      <Orders
                        title={""}
                        serviceTypeLabel={requestQuoteOp.serviceTypeLabel || ""}
                        type="Cotización"
                        companyName={requestQuoteOp.companyName}
                        rating={requestQuoteOp.serviceRating}
                        typeOperation={operation.type}
                        order={{
                          num: requestQuoteOp.numRequestQuote,
                          numComments: requestQuoteOp.numServiceComments,
                          date: FormatDate(requestQuoteOp.createdAt.toDate()),
                        }}
                        country={{
                          alpha2Code:
                            requestQuoteOp.country.alpha2Code.length > 0
                              ? requestQuoteOp.country.alpha2Code
                              : "PE",
                          imgFlag:
                            requestQuoteOp.country.imgFlag.length > 0
                              ? requestQuoteOp.country.imgFlag
                              : "https://firebasestorage.googleapis.com/v0/b/arkabia-mvp-v1.appspot.com/o/static%2Fimg%2Fflag_of_Peru.png?alt=media&token=d8bfda2c-4c48-419a-b8b9-00a423a24d53",
                          city:
                            requestQuoteOp.city.length > 0
                              ? requestQuoteOp.city
                              : "Lima",
                        }}
                        phases={PhasesImportData}
                        active={operation?.active}
                        addOrder={true}
                        flagOrder={true}
                        blackModeOrder={true}
                        resume={true}
                        price={unknown ? 0 : requestQuoteOp.price}
                        onClickPhase={handlePhaseClick}
                        showButton={!unknown}
                        // handleClick={(quotationDetail.id && quotationDetail.id.length>0) ? handleHireQuoteClick: ()=>{}}/>
                        handleClick={unknown ? () => {} : handleConfirm}
                        currency={quotationDetail.currency || "USD"}
                      />
                    </div>}
                    {/** end */}

                    {!showchat ? (
                      <>
                        {requestQuote.quoteGenerated ? (
                          <div className="ProviderSearch-right-body--badget">
                            <div className="ProviderSearch-right-body--badgetStatus ProviderSearch-colorText smalltext-header">
                              {" "}
                              Cotización
                            </div>
                          </div>
                        ) : (
                          <ImportTimelineSupplier
                            stage={quoteSend}
                            setStage={setQuoteSend}
                          />
                        )}

                        {quoteSend === 1 && requestQuote && !loading && (
                          <div className="ordersQuotationDetailPage-abstrac">
                            {quotationDetail && !quotationDetail.price && (
                              <div className="ordersQuotationDetailPage-abstrac--edit">
                                <ButtonIcon 
                                  color='black-75' 
                                  onClick={()=>setEditRq(true)} 
                                  content="Editar" 
                                  Icon={<EditWhite/>} 
                                  size="extra-small" 
                                  place="left"
                                />
                              </div>
                            )}
                            
                            <div className="ordersQuotationDetailPage-abstrac-content box-shadow-card">
                              <QuoteTransportData
                                transport={requestQuote.transport}
                             
                                isCourier={requestQuote.serviceTypeId === "courier"}
                              />
                              <QuoteCustomsData
                                requestQuoteId={requestQuote.id || ""}
                                customs={requestQuote.customs}
                                isCourier={requestQuoteOp.serviceType === "courier"}
                                updateData={()=>getRequestQuote(params.requestQuoteId || "")}
                              />
                            </div>
                            
                          </div>
                        )}

                        {quoteSend === 2 && (
                          <>
                            {!unknown ? (
                              quotationDetail &&
                              quotationDetail.importCost && (
                                <Quote
                                  edit={edit}
                                  setEdit={setEdit}
                                  rating={quotationDetail.numServiceRating}
                                  countryOrigin={quotationDetail.countryOrigin}
                                  transportInternational={quotationDetail.importCost.serviceOrigin.internationalTransport.toString()}
                                  safe={
                                    quotationDetail.importCost.serviceOrigin
                                      .insurance
                                      ? quotationDetail.importCost.serviceOrigin
                                          .insurance
                                      : ""
                                  }
                                  expensesOrigin={quotationDetail.importCost.serviceOrigin.expensiveOrigin.toString()}
                                  subTotal1={quotationDetail.importCost.serviceOrigin.subTotal.toString()}
                                  subTotal2={quotationDetail.importCost.serviceDestination.subTotal.toString()}
                                  destinationCountry={
                                    quotationDetail.destinationCountry
                                  }
                                  storage={quotationDetail.importCost.serviceDestination.storage.toString()}
                                  // warehouseExpenses={quotationDetail.importCost.serviceDestination.warehouseExpenses}
                                  storageExpenses={quotationDetail.importCost.serviceDestination.storageExpenses.toString()}
                                  onCosts={quotationDetail.importCost.serviceDestination.onCosts.toString()}
                                  operatingExpenses={quotationDetail.importCost.serviceDestination.operationExpensive.toString()}
                                  administrativeExpensive={quotationDetail.importCost.serviceDestination.administrativeExpensive.toString()}
                                  desadunaje={'quotationDetail.importCost.serviceDestination.desaduanaje.toString()'}
                                  approval={quotationDetail.importCost.serviceDestination.approval.toString()}
                                  localTransport={quotationDetail.importCost.serviceDestination.localTransport.toString()}
                                  handling={
                                    quotationDetail.importCost.serviceDestination
                                      .handling
                                      ? quotationDetail.importCost
                                          .serviceDestination.handling
                                      : "0.00"
                                  }
                                  deconsolidation={quotationDetail.importCost.serviceDestination.deconsolidation.toString()}
                                  returnContain={quotationDetail.importCost.serviceDestination.returnContain.toString()}
                                  commissionCustoms={quotationDetail.importCost.serviceDestination.commissionCustoms.toString()}
                                  otherExpenses={
                                    quotationDetail.importCost.serviceDestination
                                      .otherExpenses
                                      ? quotationDetail.importCost
                                          .serviceDestination.otherExpenses
                                      : "0.00"
                                  }
                                  tax={quotationDetail.importCost.serviceDestination.tax.toString()}
                                  total={quotationDetail.price.toString()}
                                  toValue={quotationDetail.taxes.adValorem.toString()}
                                  igv={quotationDetail.taxes.igv.toString()}
                                  ipm={quotationDetail.taxes.ipm.toString()}
                                  perception={quotationDetail.taxes.perception.toString()}
                                  isc={quotationDetail.taxes.isc.toString()}
                                  specificRight={quotationDetail.taxes.specificRight.toString()}
                                  antidumpi={quotationDetail.taxes.antidumpi.toString()}
                                  surcharge={quotationDetail.taxes.surcharge.toString()}
                                  totalTaxes={quotationDetail.taxes.subTotal.toString()}
                                  transitTime={quotationDetail.detail.transitTime}
                                  storageBase={quotationDetail.detail.storageBase}
                                  expiration={quotationDetail.detail.expiration}
                                  restrictions={quotationDetail.restriction}
                                  typeContainer={quotationDetail.typeContainer}
                                  quotationObservation={
                                    quotationDetail.observation
                                  }
                                  setQuotationObservation={() => {}}
                                  isInsurance={
                                    requestQuote.customs &&
                                    requestQuote.customs.insurance
                                      ? true
                                      : false
                                  }
                                  isFcl={
                                    requestQuote.transport?.typeContainer
                                      ?.fcl
                                      ? true
                                      : false
                                  }
                                  aboutStay={quotationDetail.detail.aboutStay}
                                  isCourier={requestQuoteOp.serviceType === "courier"}
                                  currency={quotationDetail.currency || "USD"}
                                />
                              )
                            ) : (
                              <QuoteUnknow
                                open={true}
                                open2={true}
                                edit={edit}
                                setEdit={setEdit}
                                isCourier={requestQuoteOp.serviceType === "courier"}
                              />
                            )}

                            {(requestQuote.statusProvider !== "Aceptada") && 
                            ( !unknown ?
                              <>
                                <Button
                                  id="ordersQuotationDetailPage-contract-mobile"
                                  content="Reservar"
                                  size="large"
                                  color="black-75"
                                  onClick={()=>handleConfirm()}
                                />
                                {/* <div style={{marginBottom: '2rem'}}></div> */}
                              </>
                              : null
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <div className="ordersQuotationDetailPage-chat">
                        <div className="ordersQuotationDetailPage-chat--content">
                          <MessageTopbar
                            searchText={searchText}
                            setSearchText={(e) => handleSeachChange(e.toString())}
                            team={chatdetail?.members ?  chatdetail.members.filter(m => m.userId !== userDetail.id) : []}
                            typeChat={window.innerWidth <= 880 ? "Órdenes" : ""}
                            onClickType={() => {}}
                          >
                            
                          </MessageTopbar>
                          {/* chat section */}
                          {chatdetail && 
                            <Chat 
                              chatId={chatdetail.id ? chatdetail.id : ""}
                              userId={userDetail.id}
                              userName={userDetail.name+" "+userDetail.lastName}
                              searchText={searchText}
                              members={chatdetail.members}
                              messagesUnRead={chatdetail ? chatdetail.members.find((member) => member.userId === userDetail.id)?.numMessagesUnread : 0}
                              sendWhatsappNotification={sendWhatsappNotification}
                              paramHeight={window.innerHeight}
                            />}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              
              {/** mobile button */}
              {requestQuote.statusProvider !== "Aceptada" && (
                !unknown ?
                <AbsoluteButton
                  title="Reservar"
                  handleClick={()=>setShowModalComplete(true)}
                  color="black-75"
                /> : null
              )}
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}

      <PaymentGateway
        show={showPayment}
        handlePayClick={handlePayClick}
        setShow={setShowPayment}
      />

      <ModalComplete
        showM={showModalComplete}
        setShowM={setShowModalComplete}
        handleClick={() => {
          navigation("/" + OPERATIONS);
        }}
        companyName={company.name}
        handleConfirm={handleHireQuoteClick}
        loading={loading}
      />

      <ModalSendMessage
        showModal={showModalContact}
        setShowModal={setShowModalContact}
        title="Datos de "
        agencyName={company.name}
        direction={company.direction}
        email={company.email ? company.email : ""}
        phone={company.phone ? company.phone : ""}
        whatsapp={company.whatsapp ? company.whatsapp : ""}
        message={`¡Hola *${company.name}*!%0aMi nombre es *${userDetail.name} ${userDetail.lastName}* y te escribo desde Arkabia.com`}
        handleClose={() => {}}
      />

      {/* <ModalQuote
        requestQuote={requestQuote}
        setRequestQuote={setRequestQuote}
        show={editRq}
        setShow={setEditRq}
        isUpdateRequestQuote
        updateData={handleUpdateRq}
        fromPage="serviceDetail"
      /> */}
       {editRq && <ModalProcessService
          fromPage="quotationUser"
          requestQuote={requestQuote}
          serviceTypeId={requestQuote.serviceTypeId || ""}
          show={editRq}
          setShow={setEditRq}
          companyId=""
          updatedData={handleUpdateRq}
        />}
    </>
  );
};
