import React, { useState } from 'react';

import './TenSeccion.css';
import { Questions } from '../../../Consts/Questions';
import { FaqCard } from '../../FaqCard/FaqCard';
import { GroupWhatsapp } from '../../../icons/solid/Group_whatsapp';
import { STORAGE_ASSETS } from '../../../Consts/BaseUrl';
import { Helmet } from 'react-helmet-async';

interface TenSeccionProps {
    title?: string;
}

export const TenSeccion: React.FC<TenSeccionProps> = ({
    title = "¿Tienes preguntas?"
}) => {

    const [activeIndex, setActiveIndex] = useState(0); // Inicializa con el primer elemento activo

    return (
        <div className="ten-section">
            <Helmet>
                <title>Arkabia | Agencia de Aduana</title>
                <meta name="description" 
                content="Resuelve tus dudas sobre importaciones y servicios aduaneros con 
                Arkabia. Con una calificación de 4.9/5, nuestros asesores están listos para 
                ayudarte a optimizar tus procesos." />

                <meta name="keywords" content="preguntas frecuentes, asesoría aduanera, Arkabia, 
                servicio al cliente, optimización de importaciones, dudas, agencia de aduana, 
                calificación 4.9, atención al cliente" />
            </Helmet>

            <div className='mega-header ten-seccion-white ten-seccion-title'>{title}</div>
            <div className='ten-section-questions'>
                {Questions.map((q, idx) => (
                    <FaqCard 
                        key={idx} 
                        question={q} 
                        index={idx}
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                    />
                ))}
            </div>
            <div className="ten-section-card">
                <img src={STORAGE_ASSETS+"/images/png/threeImg.png"} alt="Arkabia" className="ten-section-img"/>
                <div className="big-subheader ten-seccion-white">
                    ¿Cuentas con dudas? Lo resolvemos de inmediato con un asesor
                </div>
            </div>
        </div>
    );
};