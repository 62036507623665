import React, { useEffect, useState } from "react";

import './SearchContainer.css';
import { PlaneGray } from "../../icons/solid/PlaneGray";
import { ButtonTypeTransport } from "../TypeTransportInput/ButtonTypeTransport/ButtonTypeTransport";
import { BoatGray } from "../../icons/outline/BoatGray";
import { TypeTransport } from "../../@types/TypeTransport";
import { LocationCircle } from "../../icons/solid/LocationCircle";
import { Shipment } from "../Shipment/Shipment";
import { LocalizationTag } from "../../icons/outline/LocalizationTag";
import { CalendarAll } from "../../icons/outline/CalendarAll";
import { SearchWhite } from "../../icons/outline/SearchWhite";
import { Calendar } from "../Calendar/Calendar";
import { LCLCD } from "../CheckboxDropdown/LCLCD/LCLCD";
import { TransportInterface } from "../../interfaces/TransportInterface";
import { QuotationTransportError } from "../../interfaces/QuotationTransportError";
import { IShipment } from "../../interfaces/Shipment";
import { ConvertToKilogram } from "../../utils/ConvertToKilogram";
import { ConvertToMeters, ConvertToMetersCubic } from "../../utils/ConvertToMeters";
import { FormatDate } from "../../utils/formatDate";
import { ValidateField } from "../../utils/ValidateField";
import { ErrorInterface } from "../../interfaces/ErrorInterface";
import { toast } from "react-toastify";
import { Button } from "../Button/Button";

interface SearchContainerProps {
    setTransport: React.Dispatch<React.SetStateAction<TransportInterface>>;
    transport: TransportInterface;
    transportError: QuotationTransportError;
    setTransportError: React.Dispatch<React.SetStateAction<QuotationTransportError>>;
    handleSearch: (dateFrom: Date) => void;
    getAllContainerShared: (typeTransport: TypeTransport) => void;
}

export const SearchContainer: React.FC<SearchContainerProps> = ({
    setTransport,
    transport,
    transportError,
    setTransportError,
    handleSearch,
    getAllContainerShared
}) => {

    const refLcl = React.useRef<HTMLDivElement>(null);
    const refCalendar = React.useRef<HTMLDivElement>(null);

    const [isSelected, setIsSelected] = useState<boolean>(false);
    const [showBurden, setShowBurden] = useState<boolean>(false);
    const [showCalendar, setShowCalendar] = useState<boolean>(false);
    const [isBurdenActive, setIsBurdenActive] = useState(false);
    const [isCalendarActive, setIsCalendarActive] = useState(false);
    const [clearShipment, setClearshipment] = useState<boolean>(false);
    const [totalWeight, setTotalWeight] = useState<number>(0);
    const [totalVolume, setTotalVolume] = useState<number>(0);
    const [dateFrom, setDateFrom] = useState<Date>(new Date());



    const handleFocus = () => setIsSelected(true);
    const handleBlur = () => setIsSelected(false);

    // method to handle lcl input changes
    const handleLclchange = (e: any,
        field: 'typeMeasure' | 'totalWeight' | 'unitWeight' |'totalVolume' | 'unitVolume' | 'cargoDescription' | 'stackable' | 'file' | 'packages') => {
            // console.log('metodo',e, field);
            switch (field) {
                case 'typeMeasure':
                    setTransport((prev:any)=>({...prev, lcl: {...prev.lcl, typeMeasure: e}}));
                    break;
                case 'totalWeight':
                    setTransport((prev:any)=>({...prev, lcl: {...prev.lcl, totalWeight: e}}));
                    setTotalWeight(ConvertToKilogram(e, transport.lcl?.unitWeight));
                    break;
                 case 'unitWeight':
                    setTransport((prev:any)=>({...prev, lcl: {...prev.lcl, unitWeight: e}}));
                    setTotalWeight(ConvertToKilogram(Number(transport.lcl?.totalWeight || 0), e)); 
                    break;
                case 'totalVolume':
                    setTransport((prev:any)=>({...prev, lcl: {...prev.lcl, totalVolume: e}}));
                    setTotalVolume(ConvertToMetersCubic(e, transport.lcl?.unitVolume));
                    break;
                 case 'unitVolume':
                    setTransport((prev:any)=>({...prev, lcl: {...prev.lcl, unitVolume: e}}));
                    setTotalVolume(ConvertToMetersCubic(Number(transport.lcl?.totalVolume || 0), e));
                    break;
                case 'cargoDescription':
                    setTransport((prev:any)=>({...prev, lcl: {...prev.lcl, cargoDescription: e}}));
                    break;
                case 'stackable':
                    setTransport((prev:any)=>({...prev, lcl: {...prev.lcl, stackable: e}}));
                    break;
                case 'packages':
                    setTransport((prev:any)=>({...prev, lcl: {...prev.lcl, packages: e}}));
                    break;
                default:
                    break;
            }
            setTransportError((prev: any) => ({
                ...prev,
                lcl: {
                    ...prev.lcl,
                    totalWeight: {
                        status: false,
                        message: ""
                    },
                    totalVolume: {
                        status: false,
                        message: ""
                    }
                }
            }));
    }

    // method to handle the type of transport
    const handleTypeTransport = (e: TypeTransport) => {
        setTransport((prev:any)=>({...prev,
            type: e,
            origin: undefined,
            destination: undefined,
            lcl: undefined
        }));
        setTotalVolume(0);
        setTotalWeight(0);
        setClearshipment(true);
        getAllContainerShared(e);
    }

    // handle to change origin
    const handleChangeOrigin = (value: IShipment) => {
        setTransport((prev) => ({
            ...prev,
            origin: value
        }));
        setTransportError((prev) =>({
            ...prev,
            origin: {
                status: false,
                message: ""
            }
        }));
    }

    // handle to change destiny
    const handleChangeDestiny = (value: IShipment) => {
        setTransport((prev) => ({
            ...prev,
            destination: value
        }));
        setTransportError((prev) =>({
            ...prev,
            destination: {
                status: false,
                message: ""
            }
        }));
    }

    // method to show the burden 
    const handleShowBurden = () => {
        setShowBurden(!showBurden);
        setIsBurdenActive(true);
        setIsCalendarActive(false);
    }

    // method to show the calendar
    const handleShowCalendar = () => {
        setShowCalendar(!showCalendar);
        setIsCalendarActive(true);
        setIsBurdenActive(false);
    }

    const closeHandleShowCalendar = () => {
        setShowCalendar(false);
        setIsCalendarActive(false);
    }

    // method to handle calendar changes
    const handleCalendar = (e: Date) => {
        // validate if the date is more than yesterday
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        if(e >= yesterday) {
            setDateFrom(e);
            // setTimeout(() => {
            //     setShowCalendar(false);
            // }, 1000);
        }
    }

    // method to validate the search button
    const validateSearch = (): boolean => {
        let error = false;
        
        error = ValidateField(transport.type, "required") || ValidateField(transport.origin?.city, "required") ||
        ValidateField(transport.destination?.city, "required") || ValidateField(transport.lcl?.totalWeight, "number") ||
        ValidateField(transport.lcl?.totalVolume, "number");

        setTransportError((prev: any) => ({
            ...prev,
            origin: {
                status: ValidateField(transport.origin?.city, "required"),
                message: ValidateField(transport.origin?.city, "required") ? "El campo es requerido" : ""
            },
            destination: {
                status: ValidateField(transport.destination?.city, "required"),
                message: ValidateField(transport.destination?.city, "required") ? "El campo es requerido" : ""
            },
            lcl: {
                ...prev.lcl,
                totalWeight: {
                    status: ValidateField(transport.lcl?.totalWeight, "number"),
                    message: ValidateField(transport.lcl?.totalWeight, "number") ? "El campo es requerido" : ""
                },
                totalVolume: {
                    status: ValidateField(transport.lcl?.totalVolume, "number"),
                    message: ValidateField(transport.lcl?.totalVolume, "number") ? "El campo es requerido" : ""
                }
            }
        }));
        
        return error;
    }

    const onSeachingOrigin = () => {
        setTransportError((prev) => ({
            ...prev,
            origin: {
                status: false,
                message: ""
            },
        }))
    }

    const onSeachingDestination = () => {
        setTransportError((prev) => ({
            ...prev,
            destination: {
                status: false,
                message: ""
            },
        }))
    }


    // method to handle search button
    const handleSearchClick = () => {
       const error = validateSearch();
       if(!error) {
        handleSearch(dateFrom);
       } else {
        toast.warning("Por favor, complete los campos requeridos");
       }
    }

    useEffect(() => {
        // if the user clicks outside from refLcl
        const handleClickOutside = (e: any) => {
            if (refLcl.current && !refLcl.current.contains(e.target)) {
                handleCloseBurden();
            }
        }
        // if the user clicks outside from refCalendar
        const handleClickOutsideCalendar = (e: any) => {
            if (refCalendar.current && !refCalendar.current.contains(e.target)) {
                setShowCalendar(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("mousedown", handleClickOutsideCalendar);
        
    }, []);

    const handleCloseBurden = () => {
        setShowBurden(false);
        const error = validateSearch();
        if(error) {
            handleSearchClick();
        }
    }
    return (
        <div className="SearchContainer">
            <div className="SearchContainer-content-text">
                <div className="smalltext-header SearchContainer-content-text-1">Transporte</div>
                <div className="smalltext-header SearchContainer-content-text-2">Origen</div>
                <div className="smalltext-header SearchContainer-content-text-2">Destino</div>
                <div className="smalltext-header SearchContainer-content-text-3">Carga</div>
                <div className="smalltext-header SearchContainer-content-text-4">Listo para recojo</div>
            </div>

            <div className="SearchContainer-content-card">
                <div className="smalltext-header SearchContainer-text-mobile">Medio de transporte</div>
                <div className={`SearchContainer-content-row-1 ${
                        isBurdenActive || isCalendarActive ? "z-index-1" : "z-index-2"
                    }`}>
                    <div className="SearchContainer-content-row">
                        <ButtonTypeTransport 
                            icon={<BoatGray className="icon-boat" />}
                            title=""
                            isActive={transport.type==='sea'}
                            onClick={()=>handleTypeTransport('sea')}
                        />
                        <ButtonTypeTransport 
                            icon={<PlaneGray className="icon-plane"/>}
                            title=""
                            isActive={transport.type==='air'}
                            onClick={()=>handleTypeTransport('air')}

                        />
                    </div>
                    <div className="SearchContainer-content-row_2 SearchContainer-content-row_2_"> 
                        <div className="SearchContainer-column_error" style={{zIndex: 3}}>
                            <div className="SearchContainer-content-direction">
                                <LocationCircle/>
                                <Shipment 
                                    text={`Ciudad, ${transport.type === "air" ? "Aeropuerto" : "Puerto"}`}
                                    type={transport.type} 
                                    shipment={ {city: '', country: ''}} 
                                    setShipment={handleChangeOrigin} 
                                    role='errorOrigin-origin-destiny'
                                    disabled={false}
                                    clearShipment={clearShipment}
                                    setClearShipment={setClearshipment}
                                    setSearchActive={() =>onSeachingOrigin()}
                                    handleFocus={handleFocus}
                                    handleBlur={handleBlur}
                                    showOnlyPort
                                />
                            </div>
                            {transport.destination &&
                                <div className="text-error tinytext-header">{transportError.origin.message}</div>
                            }
                        </div>

                        <div className="SearchContainer-column_error">
                            <div className="SearchContainer-content-direction SearchContainer-content-row__display">
                                <LocalizationTag/>
                                <Shipment 
                                    text={`Ciudad, ${transport.type === "air" ? "Aeropuerto" : "Puerto"}`}
                                    type={transport.type} 
                                    shipment={ {city: '', country: ''}} 
                                    setShipment={handleChangeDestiny} 
                                    role='errorOrigin-origin-destiny'
                                    disabled={false}
                                    clearShipment={clearShipment}
                                    setClearShipment={setClearshipment}
                                    setSearchActive={() => onSeachingDestination()}
                                    showOnlyPort
                                />
                            </div>
                            {transportError?.origin && (
                                <div className="text-error tinytext-header">{transportError.destination.message}</div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="SearchContainer-content-row-text">
                    <div className="smalltext-header SearchContainer-content-text-3">Carga</div>
                    <div className="smalltext-header SearchContainer-content-text-4">Listo para recojo</div>
                </div>

                <div className={`SearchContainer-content-row-1 SearchContainer-content-row__display ${
                        showCalendar || showBurden ? (isBurdenActive || isCalendarActive  ? "z-index-12" : "z-index-2") : "z-index-2"
                    }`}>
                    <div className="SearchContainer-content-row_2">
                        <div className="SearchContainer-content-row_2_weight">
                            <div className="SearchContainer-column_error">
                                <div className="SearchContainer-content-direction SearchContainer-width_xs"
                                    onClick={handleShowBurden}>
                                    <div>
                                        <div className="SearchContainer-text-date">{totalWeight+" kg ; "+totalVolume+" m³"}</div>
                                    </div>
                                </div>
                                {(transportError.lcl?.totalWeight.status ||transportError.lcl?.totalVolume.status) && <div className="text-error tinytext-header">{"Ingrese la carga"}</div>}
                            </div>
                            
                            {/* Aquí se muestra el modal de la carga*/}
                            {showBurden &&
                            <div className="SearchContainer-lcl" ref={window.innerWidth>600 ? refLcl : null} >
                                <div className="SearchContainer-modal-lcl" ref={window.innerWidth<=600 ? refLcl : null}>
                                    <div style={{width: '100%',display: 'flex',flexDirection: 'column'}}>
                                        <div className="SearchContainer-line" onClick={()=>setShowBurden(!showBurden)}></div>
                                        <LCLCD
                                            isChecked={true}
                                            setIsChecked={() => {}}
                                            totalWeight={transport.lcl?.totalWeight}
                                            handleTotalWeight={(e: any) => handleLclchange(e, 'totalWeight')} 
                                            totalWeightUnit={transport.lcl?.unitWeight}
                                            setTotalWeightUnit={(e: any) => handleLclchange(e, 'unitWeight')}
                                            totalVolume={transport.lcl?.totalVolume}
                                            handleTotalVolume={(e: any) => handleLclchange(e, 'totalVolume')}
                                            totalVolumeUnit={transport.lcl?.unitVolume}
                                            setTotalVolumeUnit={(e: any) => handleLclchange(e, 'unitVolume')}
                                            cargoDescription={transport.lcl?.cargoDescription}
                                            setCargoDescription={()=>{}}
                                            errorLCL={transportError.lcl}
                                            typeMeasure={transport.lcl?.typeMeasure}
                                            setTypeMeasure={(e: any) => handleLclchange(e, 'typeMeasure')}
                                            packages={transport.lcl?.packages ? transport.lcl?.packages : []}
                                            setPackages={(e: any) => handleLclchange(e, 'packages')}
                                        />
                                        <div className="SearchContainer-modal-lcl--button"><Button size="large" color="black-75" content="Confirmar" onClick={()=>handleCloseBurden()} /></div>
                                        </div>
                                    </div>
                            </div>}
                        </div>

                        <div className="SearchContainer-content-row_2_calendar">
                            <div className="SearchContainer-content-direction SearchContainer-width_s"
                                onClick={handleShowCalendar} ref={refCalendar} >
                                <CalendarAll />
                                <div>
                                    <div className="SearchContainer-text-date">{FormatDate(dateFrom)}</div>
                                </div>
                            </div>
                            
                            {/* Aquí se muestra el modal de la carga*/}
                            {showCalendar && 
                            <div className="SearchContainer-calendar"  ref={window.innerWidth>600 ? refCalendar : null}>
                                <div className="SearchContainer-modal-calendar" ref={window.innerWidth<=600 ? refCalendar : null}>
                                    <div style={{width: '100%',display: 'flex',flexDirection: 'column', alignItems: 'center'}}>
                                        <div className="SearchContainer-line" onClick={closeHandleShowCalendar}></div>
                                        <Calendar
                                            setDay={(e)=>handleCalendar(e)} 
                                        />
                                    </div>
                                </div>
                            </div>}
                        </div>
                        
                    </div>
                    <div className="SearchContainer-button" onClick={()=>handleSearchClick()} >
                        <SearchWhite/>
                        <div className="smalltext-header SearchContainer-button-text">Buscar</div>
                    </div>
                </div>
            </div>
        </div>
    ) 
}