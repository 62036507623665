export const AnimationGallery: string[] = [
    'galery1',
    'galery2',
    'galery3',
    'galery4',
    'galery5',
    'galery6',
]

export const AnimationGallerySecond: string[] = [
    'galery1',
    'galery7',
    'galery8',
    'galery6',
    'galery9',
    'galery2',
]